import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/core/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class ResourcePermissionService {
  availablePrivileges: any[] = [];
  selectedBloomWorkspace: any;
  isCollaboratedBloom: boolean;
  isBloomPrivilagesSet: boolean = false;
  isConnectionPrivilagesSet: boolean = false;

  constructor(
    public cs: ConnectionService,
    public http: HttpClient,
    public authServiceService: AuthServiceService,
    public workspaceService: WorkspaceService,
    public adminService: AdminService,
    public permissionService: PermissionService
  ) {}

  async createPermissions(body) {
    console.log('permission', body);
    let url = `${environment.SERVER_BASE_URL}/resource-permission/bulk`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .post(url, body, { headers: headers })
        .toPromise();
      console.log('response', response);
      result = response.data;
    } catch (e) {
      console.log('Error while getting permission', e);
    }
    return result;
  }

  async checkAndCreatePermission(body) {
    console.log('permission', body);
    let url = `${environment.SERVER_BASE_URL}/resource-permission/checkandcreate`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .post(url, body, { headers: headers })
        .toPromise();
      console.log('response', response);
      result = response.data;
    } catch (e) {
      console.log('Error while getting permission', e);
    }
    return result;
  }

  async getPermission(id) {
    let url = `${environment.SERVER_BASE_URL}/resource-permission/${id}`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .get(url, { headers: headers })
        .toPromise();
      console.log('response', response);
      result = response.data;
    } catch (e) {
      console.log('Error while getting permission', e);
    }
    return result;
  }

  async getPermissions(resourceid, isClearCache: boolean = false) {
    console.log('getPermissions resourceid', resourceid);
    console.log('getPermissions isClearCache', isClearCache);
    let url = `${environment.SERVER_BASE_URL}/resource-permission/list/${resourceid}?isClearCache=${isClearCache}`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .get(url, { headers: headers })
        .toPromise();
      console.log('getPermissions response', response);
      result = response.data;
    } catch (e) {
      console.log('Error while getting permissions', e);
    }
    return result;
  }


  async getSharedResources(user_id, resource_type:string, pageNumber?: number, pageSize?: number) {
    console.log("User Id :",user_id,"\nselectedWorkspace : ",this.cs.selectedWorkSpace, pageNumber, pageSize);
    let url = `${environment.SERVER_BASE_URL}/resource-permission/sharedresources/${user_id}/${resource_type}?currentworkspaceid=${this.cs.selectedWorkSpace}`;
    if (pageNumber && pageSize) {
      url += `&pagenumber=${pageNumber}&pagesize=${pageSize}`
    }
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("getSharedREsources response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting user resource", e)
      throw e
    }
    return result;
  }

  async deletePermission(id) {
    let url = `${environment.SERVER_BASE_URL}/resource-permission/${id}`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .delete(url, { headers: headers })
        .toPromise();
      console.log('response', response);
      result = response.data;
    } catch (e) {
      console.log('Error while deleting permission', e);
    }
    return result;
  }

  async getPermissionForUser(userId, resourceId, token?) {
    let url = `${environment.SERVER_BASE_URL}/resource-permission/list/${resourceId}/${userId}`;
    console.log('url to get permission', url);
    var result: any = {};
    try {
      let preAuthenticatedToken = token ? token : this.cs.preAuthenticatedToken;
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + preAuthenticatedToken,
      };
      var response: any = await this.http
        .get(url, { headers: headers })
        .toPromise();
      console.log('getPermissionForUser response', response);
      result = response;
    } catch (e) {
      console.log('Error while getting permission for user', e);
    }
    return result;
  }

  async updatePermission(permission: any) {
    let url = `${environment.SERVER_BASE_URL}/resource-permission`;
    var result: any = {};
    try {
      var headers = {
        Authorization: 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken,
      };
      var response: any = await this.http
        .put(url, permission, { headers: headers })
        .toPromise();
      console.log('response', response);
      result = response?.data?.[0] || null;
      console.log('[permission] update: ', result);
    } catch (e) {
      console.log('Error while updating permission', e);
    }
    return result;
  }

  // From Bloom-Permission Service

  async setBloomPrivilages(bloom, userMap) {
    var roleMap:any = {};

    let result = true;
    var roles = [];

    if(bloom.isPermissionDelinked && userMap){
      var userId = userMap._id;

      let preAuthenticatedToken = this.cs.getPreAuthenticatedTokenForEmail(userMap.email);
      console.log("preAuthenticatedToken", preAuthenticatedToken);

      roleMap = await this.getPermissionForUser(userId, bloom.code, preAuthenticatedToken);
      console.log("roleMap", roleMap);

      if((!roleMap || !roleMap.roles) && bloom.access_type == "public" && bloom.default_user_role ){
        let role = await this.adminService.getByIdRole(preAuthenticatedToken, bloom.default_user_role)
        roles = [role?.role];
        console.log("roleMap default", role);
      } else if ((!roleMap || !roleMap.roles) && bloom.access_type == "private"){
        if(userMap.email !== bloom.created_by){
          result = false;
        }
      } else {
        roles = await this.adminService.getRolesbyIds(roleMap?.roles, preAuthenticatedToken);
        console.log("roles", roles);
      }
    } else if(bloom.access_type == "public"){
      let preAuthenticatedToken = this.cs.getPreAuthenticatedTokenForEmail(bloom.publisher_email);
      let role = await this.adminService.getByIdRole(preAuthenticatedToken, bloom.default_user_role);
      console.log("roles-->", role);
      if(role?.role) roles = [role?.role];
    }

    roles?.forEach((e: any) => {
      e.privileges?.forEach(element => {
        if(!this.availablePrivileges.includes(element)) this.availablePrivileges.push(element);
      });
      // this.availablePrivileges = this.availablePrivileges.concat(e.privileges);
    })

    console.log("[BLOOM-PERMISSION] availablePrivileges", this.availablePrivileges)
    this.isBloomPrivilagesSet = true;
    return result;
  }

  //
  async setConnectionsPrivilages() {
    this.availablePrivileges.forEach(element => {
      let privilege = element?.split(".")[1];
      if(privilege && !this.availablePrivileges.includes(`connection.${privilege}`)) this.availablePrivileges.push(`connection.${privilege}`);
    });
    this.isConnectionPrivilagesSet = true;
    console.log("[CONNECTION-PERMISSION] availablePrivileges", this.availablePrivileges)
  }

  checkAccess(configs){
    let obj = {};

    var securities = configs.securities || [];
    for(let i = 0; i < securities.length; i++){
      let access = false;
      console.log("permission securities[i]", securities[i])
      if(securities[i].condition == "contain"){
        access = this.hasAccess(securities[i].privileges)
        console.log("permission result", access)
      } else if(securities[i].condition == "not-contain"){
        console.log("permission result bc", this.hasAccess(securities[i].privileges))
        access = !this.hasAccess(securities[i].privileges)
      }

      obj[securities[i].effect] = access;
    }

    console.log("checkAccess configs", configs, "checkAccess return", obj)
    return obj;
  }


  hasAccess(neededPrivileges: any): boolean {
    let result = false;
    // console.log("availablePrivileges", this.availablePrivileges)
    // console.log("neededPrivileges", neededPrivileges)

    if(this.availablePrivileges.length > 0){
       result = this.checkPrivilege(neededPrivileges);
    }
    return result
  }

  checkPrivilege(neededPrivileges:any){
    var result = false;
    neededPrivileges.every(privilege =>{
      if(this.availablePrivileges.includes(privilege)){
        result = true;
        return result;
      }
    })
    return result;
  }

  async setWorkspacePrivilages(bloom) {
    this.availablePrivileges = [];
    var roleMap:any = {};

    var userId = this.cs.userProfile._id;
    var workSpaceId = this.cs.workSpaceId;

    if(bloom.isPermissionDelinked){
      roleMap = await this.getPermissionForUser(userId, bloom.code);
      this.selectedBloomWorkspace = roleMap.workspace_id;
    }else {
      roleMap = await this.workspaceService.getPermissionForUser(userId, workSpaceId);
    }

    console.log("roleMap", roleMap)

    var roles = await this.adminService.getRolesbyIds(roleMap.roles, this.cs.preAuthenticatedToken);

    roles.forEach((e: any) => {
      this.availablePrivileges = this.availablePrivileges.concat(e.privileges);
    })
    console.log("[WORKSPACE-PERMISSION] availablePrivileges", this.availablePrivileges)
  }

  async inheritWorkspacePermissions(workspaceId: any, resourceCode: any, resourceType?:any){
    var res;
    try {
      var workspacePermissions = await this.workspaceService.getPermissions(workspaceId);
      console.log("workspacePermissions", workspacePermissions)
      var bloomPermissions = [];
      for(var i = 0; i < workspacePermissions.length; i++){
        // workspacePermissions[i].bloom_id = bloomId;
        workspacePermissions[i].roles = this.inheritRoles(workspacePermissions[i].roles, resourceType || "bloom");
        workspacePermissions[i].resource_type = resourceType || "bloom";
        workspacePermissions[i].resource_id = resourceCode;
        bloomPermissions.push(workspacePermissions[i]);
      }
      console.log("bloomPermissions", bloomPermissions)
      res = await this.createPermissions(bloomPermissions);
    }catch(e){
      throw e;
    }
    return res;
  }

  inheritRoles(roles, resourceType){
    let result = [];
    let existingRoleIdMap = this.permissionService.getRoleIdMap();
    console.log("[inheritRoles] existingRoleIdMap", existingRoleIdMap)
    console.log("[inheritRoles] rolemap", this.permissionService.roleMap)
    roles?.forEach(element => {
      let existingRoles = this.permissionService.roleInheritMap[resourceType][existingRoleIdMap[element]];
      console.log("[inheritRoles] existingRoles", existingRoleIdMap)
      if(!existingRoles){
        result.push(element);
      } else {
        existingRoles?.forEach(ele => {
          let role = this.permissionService.roleMap[ele];
          result.push(role);
        });
      }
    });
    return result;
  }
}
