<spinner *ngIf="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div *ngIf="onlyDisplay" fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" fxFlex="10">
    <mat-icon color="primary" matPrefix>storage</mat-icon>
  </div>

  <div fxLayout="column" fxFlex="10" *ngIf="box.logo">
    <img [src]="box.logo" class="image-size" />
  </div>

  <div fxLayout="column" fxFlex="70">
    {{ box.name }}
  </div>

  <div fxLayout="column" fxFlex="10"></div>
</div>

<div fxLayout="row" *ngIf="!onlyDisplay" fxLayoutAlign="start center">
  <mat-form-field style="width: 100%;" fxLayout="column">
    <mat-icon color="primary" matPrefix>storage</mat-icon>
    <mat-label>
      <span>&nbsp;Choose {{boxType ? boxType : 'Application'}}</span>
    </mat-label>

    <div fxLayout="row" fxLayoutAlign="start center"  [matTooltip]="box.name">
      <input #appinput matInput [formControl]="appListingACFrom" (focus)="setFilterOptions()"
        [matAutocomplete]="auto" />
      <img *ngIf="appListingACFrom.value && box.logo" [src]="box.logo" class="image-size" />
    </div>

    <button *ngIf="appListingACFrom.value" matTooltip="Clear selected {{boxType ? boxType : 'Application'}}" matSuffix
      mat-icon-button aria-label="Clear" (click)="clearSelection($event)">
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunction"
      (optionSelected)="appSelected($event.option.value)">
      <mat-option [value]="starchMap" *ngIf="supportStarch">
        <mat-icon color="accent">dataset</mat-icon>
        Starch
      </mat-option>
      <mat-divider></mat-divider>
      <mat-option *ngFor="let option of appListingOptions | async" [value]="option">
        <img [src]="option.logo" class="image-size" />&nbsp;
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div>
    <button mat-icon-button color="primary" (click)="appsPopup()"
      matTooltip="Browse and choose {{boxType ? boxType : 'Application'}}" aria-label="View Available Apps">
      <mat-icon>dashboard</mat-icon>
    </button>
  </div>

  <mat-spinner *ngIf="spinner" color="warn" [diameter]="20"></mat-spinner>
</div>