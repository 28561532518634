import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { BoxService } from 'src/app/shared/services/box.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-action-selection',
  templateUrl: './action-selection.component.html',
  styleUrls: ['./action-selection.component.scss']
})
export class ActionSelectionComponent implements OnInit {
  boxConfigToken: any;
  isOptionsToCollect: boolean;
  attributeOptions: any[];
  selectedActionId: any;


  constructor(
    private boxService: BoxService,
    private pageService: PageService,
    private spin: SpinnerService
  ) { }
  spinner: boolean = false;
  @ViewChild('actionInput') actionInput: ElementRef;
  @Input() config: any;
  @Input() actionType:any;
  @Input() isObjectFn?: boolean = false;
  @Input() collectExtraOptions?: boolean = true;
  @Input() existingActionId: any;
  @Input() display: boolean = true;
  @Output() "selectedAction" = new EventEmitter<any>();
  @Output() "allActions" = new EventEmitter<any>();
  @Output() "actionFields" = new EventEmitter<any>();
  @Output() "selectedBoxObject" = new EventEmitter<any>();
  @Output() "attributeOptionsInput" = new EventEmitter<any>();
  @Output() "isSteppedInputRequired" = new EventEmitter<any>();
  @Input() onlyDisplay: boolean = false;
  isEdit:boolean = false;


  actionListingACFrom = new UntypedFormControl();
  actionListingOptions: any;

  actions: any = [];

  boxId:string;
  connectionId:string;
  boxObjectName: string;
  boxObjectId: string;

  currentSelectedAction: any;

  async ngOnInit() {
    this.spinner = true;
    this.spin.show()
    console.log("config in action", this.config)
    console.log("existingActionId", this.existingActionId)
    if(this.config){
      this.boxId = this.config.box_id;
      this.connectionId = this.config.connection || null;
      this.boxObjectId = this.config.box_object_id || null;
      this.boxObjectName = this.config.box_object_name || null;
      this.boxConfigToken = this.config.boxConfigToken || null;
    }

    try {

      if(this.existingActionId && typeof this.existingActionId === "string"){
        console.log("[ACTION SELECTION] string input", this.existingActionId)
        //if () { //TODO - get from cache if not there load
        await this.setAction();
      } else if(this.existingActionId && Object instanceof this.existingActionId){
        await this.setAction();
      }

      console.log("this.actions ", this.actions )
      this.actions = this.actions?.sort((a, b) => a.name.localeCompare(b.name)) || [];
      // Emit the sorted actions
      this.allActions.emit(this.actions);

      if(this.config.supportCrudTypes){
        await this.getFilteredActions();
      }
      // console.log("this.actions ", this.actions );
      this.spinner = false;
      this.spin.hide()


    } catch (errorObj) {
      const errorMsg = errorObj?.error?.error?.errorMap?.error || errorObj?.error?.error || "Error occured while fetching action!";
      this.actionListingACFrom.setErrors({errorMsg});
    }
    if(this.config?.focus){
      this.focusActionInput();
    }

  }

  focusActionInput(): void {
    this.actionInput.nativeElement.focus();
    this.setStepFilterOptions();
  }

  async setAction(){
      this.spinner = true;
      this.spin.show()
      let conType = null;
      let box_id = this.boxId;
      let connectionId = JSON.parse(JSON.stringify(this.connectionId));
      if(!this.connectionId && this.boxConfigToken){
        conType = 'token';
        connectionId = this.boxConfigToken;
        if( this.config.base_box_id) box_id = this.config.base_box_id;
      }
      let action = await this.boxService.getAction(box_id, this.existingActionId, connectionId, this.config, null, conType);
      this.currentSelectedAction = action;

      this.actionListingACFrom.setValue(action);

      if(action?.isSteppedInputRequired && this.isObjectFn && this.isEdit){
        var allActions = await this.getActions(this.boxId, this.connectionId);
        this.allActions.emit(allActions);
        this.isSteppedInputRequired.emit(true);
      }
      try {
        let fields = await this.getActionFields(action.__id, this.boxId, this.connectionId, action);
        console.log("action fields", fields)
        this.actionFields.emit(fields || []);
      } catch (e){
        console.log("[E] error on get action fields", e)
      } finally {
        this.selectedAction.emit(action);
        this.spinner = false;
        this.spin.hide()
      }


  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes in action", changes)

    if(changes?.config?.currentValue?.focus){
      this.focusActionInput();
    }
    if(!changes?.config?.firstChange && changes?.config?.currentValue?.box_id){
      console.log("inside if")
      this.boxId = changes?.config?.currentValue.box_id;
      this.connectionId = changes?.config?.currentValue.connection || null;
      this.boxObjectId = changes?.config?.currentValue.box_object_id || null;
      this.boxConfigToken = changes?.config?.currentValue.boxConfigToken || null;

      this.spinner = true;
      this.spin.show()
      this.boxId = changes.config.currentValue?.box_id;
      if(changes.config.currentValue?.connection) this.connectionId = changes.config.currentValue?.connection;
      if(!this.currentSelectedAction && this.isEdit){
        console.log("no current selected action")
        this.actions = await this.getActions(this.boxId, this.connectionId);
        if(this.existingActionId || this.connectionId){
          await this.setAction();
        }
      }
      this.spinner = false;
      this.spin.hide()
    }

    if(!changes?.config?.firstChange && changes?.config?.currentValue?.connection){
      if(this.currentSelectedAction) {
        let fields = await this.getActionFields(this.currentSelectedAction.__id, this.boxId, this.connectionId, this.currentSelectedAction);
        this.actionFields.emit(fields || []);
        this.selectedAction.emit(this.currentSelectedAction);
      }
    }

  }

  getFilteredActions(){
    var actionCopy = this.actions;
    this.actions = [];
    // console.log(actionCopy);
    console.log(this.config.supportCrudTypes);
      var crudTypes = this.config.supportCrudTypes.split(",");
      console.log(crudTypes);
      for(var action of actionCopy){
        var crudType : string = action.crudType;
        if(crudType){
          if(crudTypes.includes(crudType)){
            if(this.config.isListOnlyObject && crudTypes.length == 1) {
              if(action.objectId) action.name = this.boxObjectName || action.objectId;
            }
            this.actions.push(action);
            continue;
          }
          else{
            continue;
          }

        }
        // this.actions.push(action);  // if crudtype is not in box meta actions will not get pushed
      }
  }

  async getActions(box_id: any, connectionId?:any, options?: any) {
    console.log("[ACTION SELECTION] getAllActions(): box_id", box_id, "connectionId", connectionId);

    let conType = null;
    // console.log("boxConfigToken in action", this.boxConfigToken, this.config)
    if (this.boxConfigToken){
      conType = 'token';
      connectionId = this.boxConfigToken;
      if(this.config.base_box_id) box_id = this.config.base_box_id;
    }
    let res: any;
    try{
      res = await this.boxService.getAllActions(box_id, connectionId, options, conType)
      // Sort actions by name
      res = res.sort((a, b) => a.name.localeCompare(b.name));
    }catch(e){
      console.log("error occurred", e)
    }
    return res
  }

  async getObjectActions(box_id: any, box_object_id: any, connectionId?:any) {
    let conType = null;
    if(!connectionId) connectionId = null;
    else if (this.boxConfigToken){
      conType = 'token';
      connectionId= this.boxConfigToken;
      if( this.config.base_box_id) box_id = this.config.base_box_id;
    }
    console.log("box_id box_object_id connectionId conType", box_id, box_object_id, connectionId, conType);
    return await this.boxService.getObjectActions(box_id, box_object_id, connectionId, conType, this.boxObjectName)
  }

  async actionSelected(action: any){

    if(!this.spinner) this.spinner = true;
    console.log("selected action", action);
    if(action?.isSteppedInputRequired && this.isObjectFn){
      var allActions = await this.getActions(this.boxId, this.connectionId);
      this.allActions.emit(allActions);
      this.isSteppedInputRequired.emit(true);
    }
    this.currentSelectedAction = action
    // this.selectedAction.emit(action);

    //if connection not provided it should not load fields
    if(!this.connectionId && !this.boxConfigToken) {
      this.spinner = false;
      return;
    }
    let fields = await this.getActionFields(action.__id, this.boxId, this.connectionId, action);
    this.selectedAction.emit(this.currentSelectedAction)
    console.log("actionSelected() --> emitting selected fields", fields);
    this.actionFields.emit(fields || []);
    this.emitBoxObject(action);
    this.spinner = false;
  }

  emitBoxObject(action){
    if(action.__id.includes('/')){
      console.log("box object is:", action.__id.split('/')[0])
      this.selectedBoxObject.emit(action.__id.split('/')[0])
    }else{
      console.log("no box object associated with this action")
      this.selectedBoxObject.emit(null)
    }
  }

  async getActionFields(actionId:any, boxId:string, connectionId:string, functionMap?:any){
    this.spinner = true;
    this.spin.show()
    console.log("[ACTION SELECTION] getAction Fields functionMap", functionMap, actionId)
    let conType;
    if (this.boxConfigToken){
      conType = 'token';
      connectionId= this.boxConfigToken;
      if(this.config.base_box_id) boxId = this.config.base_box_id;
    }
    let attributes: any
    if(actionId.includes("/")){ //box object
      let boxActions = await this.boxService.getBoxActions(boxId, connectionId, conType);
      console.log("[ACTION SELECTION] boxActions", boxActions)
      let fn = boxActions.find(fn => fn.__id == 'getattributes')
      let inputOptions = {};
      let isValuesAvailable = true;
      if(fn){
        let options = this.pageService.checkOptionsToCollect(fn);
        let list = [];
        options.forEach(element => {
            if(!element.hidden) list.push(element);
        });
        options = list;
        console.log("options to collect", list, this.config.actionMap?.attributeInputOptions)
        if(this.collectExtraOptions && options && Array.isArray(options) && options.length){

          this.attributeOptions = options
          this.isOptionsToCollect = true;
          let existingMap = this.config.actionMap?.attributeInputOptions || {};
          for (let index = 0; index < this.attributeOptions.length; index++) {
            const element = this.attributeOptions[index];
            if(existingMap[element.__id || element.name]){
              element.value = existingMap[element.__id || element.name];
            } else if(element.defaultValue) {
              element.value = element.defaultValue;
            } else if(!element.value) isValuesAvailable = false;
          }
        } if(!this.isOptionsToCollect) isValuesAvailable = false;
      }
      this.selectedActionId = actionId;

      if(this.config.base_box_id){
        inputOptions = Object.assign(inputOptions, {relationObject: "starch_relationship"})
      }

      console.log("isOptionsToCollect", this.isOptionsToCollect, isValuesAvailable, this.attributeOptions, inputOptions)

      if (this.attributeOptions && this.attributeOptions.length) {
        this.attributeOptions.forEach(option => {
          inputOptions[option.__id] = option.value
        });
      }

      if((!this.isOptionsToCollect && !isValuesAvailable) || (this.isOptionsToCollect && isValuesAvailable)) {
        // this.selectedAction.emit(this.currentSelectedAction)
        attributes = await this.boxService.getAttributes(boxId, actionId, connectionId, this.boxConfigToken, inputOptions)
        console.log("[ACTION SELECTION] attributes", attributes)
      }
    } else {
      // this.selectedAction.emit(this.currentSelectedAction)
      attributes = this.constructAppFields(functionMap)
    }
    this.spinner = false;
    this.spin.hide()
    console.log("attributes fetched", attributes)
    return attributes;
  }

  async getAttributes(){
    console.log("[ACTION SELECTION] getAttributes")
    this.boxId, this.connectionId;
    let inputOptions:any = {};
    if (this.attributeOptions && this.attributeOptions.length) {
      this.attributeOptions.forEach(option => {
        inputOptions[option.__id] = option.value
      });
    }
    let fields = await this.boxService.getAttributes(this.boxId, this.selectedActionId, this.connectionId, this.boxConfigToken, inputOptions)
    console.log("emitting attributeOptionsInput", inputOptions)
    fields?.forEach(element => {
      if(!element.dataType) element.dataType = "string"
    });
    this.selectedAction.emit(this.currentSelectedAction);

    this.attributeOptionsInput.emit(inputOptions);
    console.log("emitting actionFields", fields || [])
    this.actionFields.emit(fields || []);
  }

  attributeOptionChanged(event, index){
    this.attributeOptions[index]['value'] = event.srcElement.value
  }

  constructAppFields(functionMap:any){
      let fields = [];
      let input = functionMap?.input;
      for (let attribute of input.list) {
        fields.push(input[attribute])
      }

      // if(functionMap?.output?.list) {
      //   for (let attribute of functionMap.output.list) {
      //     fields.push(input[attribute])
      //   }
      // }

      //Commenting this as inapropriate here in app fields
      // let customOutputs = [
      //   {
      //     name: "Function Result",
      //     __id: "__result",
      //     type: "array"
      //   }
      // ]
      // fields = fields.concat(customOutputs);
      return fields;
    }

  async setStepFilterOptions() {
    // console.log("[ACTION SELECTION] setStepFilterOptions: this.actions", this.actions)
    if(!this.isEdit){
      if (this.isObjectFn) {
        this.actions = await this.getObjectActions(this.boxId, this.config.box_object_id, this.connectionId);
      } else {
        let options = {
          box_object: this.boxObjectId
        };
        this.actions = await this.getActions(this.boxId, this.connectionId, options);
      }
    }
    if(this.config.supportCrudTypes) this.getFilteredActions();
    this.isEdit = true;
    this.actionListingOptions = this.actionListingACFrom.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value, this.actions))
    );
  }

  private _filter(value: string, array?:any): string[] {
    // console.log("[ACTION SELECTION] in _filter", value, array)
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return array.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

}
