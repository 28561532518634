import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service'
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { FlowService } from 'src/app/flow/flow.service';
import { FormService } from 'src/app/form/form.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { StarchService } from './starch.service';
import { MatSnackBar } from '@angular/material/snack-bar';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon: string;
  snackBarError: boolean;
  snackBarDuration: number;
}

@Injectable({
  providedIn: 'root'
})

export class ResourceDeletionService {

  snackBarObj: any;
  spinner:boolean = false;
  
  constructor(
    private http: HttpClient,
    public authService: AuthServiceService,
    public clientPlatformService: ClientPlatformService,
    public connectionService: ConnectionService,
    public metaService: MetaService,
    public fs: FlowService,
    public formService: FormService,
    public starchService: StarchService,
    private _snackBar: MatSnackBar,
  ) { }

  openSnackBar(snackBarObj: snackBarValues) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
      },
      duration: snackBarObj.snackBarDuration,
      horizontalPosition: 'end',
    });
  }

  /**
   * @param data 
   * @returns {
  *  data: { rowDataRaw: original record object to be deleted, resource: 'connection' | 'bloom' | 'forms' | 'starch' }
  *  response: { data: deleted record object, response: response from server }
  * }
  */
 async delete(data: any){
   console.log("delete() hit with data", data)
   let res = { data: data, response: null }
   let deleteResponse: any
   try {
     if(data?.resource == "connection"){
       deleteResponse = await this.deleteConnection(data.rowDataRaw)
     }else if(data?.resource == "bloom"){
       deleteResponse = await this.deleteBloom(data.rowDataRaw)
     // }else if(data?.resource == "Flow"){
     //   deleteResponse = await this.resourceDeletionService.deleteFlow(data.dataObj)
     }else if(data?.resource == "forms"){
       deleteResponse = await this.deleteForm(data.rowDataRaw)
     }else if(data?.resource == "starch"){
       deleteResponse = await this.deleteStarch(data.rowDataRaw)
     } else {
       throw new Error("unsupported resource type")
     }
     res['response'] = deleteResponse
     return res
   } catch(e) {
     console.error("deletion failed", e)
     throw e
   }
 }

  async deleteConnection(connection){
    try {
      let response = await this.connectionService.deleteConnection(connection._id, this.connectionService.preAuthenticatedToken)
      console.log("Connection deletion responce", response);
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Connection Deleted Successfully',
        snackBarIcon: 'check_circle',
        snackBarDuration: 2000,
        snackBarError: false,
      };
      this.openSnackBar(this.snackBarObj); 
      return response 

    } catch(error) {
      console.log("connection could not be deleted", error);
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Connection',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      throw error
    }
  }

  async deleteBloom(bloom){
    try {
      let deleteResponse = await this.metaService.deleteBloom(bloom);
      console.log('bloom deletion response ------> ', deleteResponse);
      if(deleteResponse && bloom.publishedUrl){
        let publishedBloom = await this.metaService.getBloomByCode(bloom.code)
        console.log("published bloom found", publishedBloom)
        if(publishedBloom.length){
          for(let i=0; i<publishedBloom.length; i++){
            let deletePublished = this.metaService.deleteBloom(publishedBloom[i]);
            console.log("published bloom deleted", deletePublished)
          }
        }
      }
      if(deleteResponse){
        this.spinner = false;
        this.snackBarObj = {
          snackBarMessage: 'Bloom Deleted Successfully',
          snackBarIcon: 'check_circle',
          snackBarDuration: 2000,
          snackBarError: false,
        };
        this.openSnackBar(this.snackBarObj);
      }
      return deleteResponse;

    }catch(error){
      console.log(error);
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Bloom',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      throw error
    }

  }

  async deleteFlow(flow){
    try{
      var result = await this.fs.deleteFlow(flow);

      // delete published flow also
      if(result && flow.published_flow_id) {
        let res = this.fs.deleteFlow({_id: flow.published_flow_id})
        console.log("published flow deleted", res)
      }
      console.log("Flow deletion result", result);
      if(result){
        this.spinner = false;
        this.snackBarObj = {
          snackBarMessage: 'Flow Deleted Successfully',
          snackBarIcon: 'check_circle',
          snackBarDuration: 2000,
          snackBarError: false,
        };
        this.openSnackBar(this.snackBarObj);
      }
      return result;
        
    } catch(error) {
      console.log("could not delete flow", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Flow',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      throw error
    }
  }

  async deleteForm(form){
    try {
      let deleteResponse = await this.formService.deleteForm(form);
      console.log('form deletion response ------> ', deleteResponse);
      if(deleteResponse && form.publishedUrl){
        let filterStr = `code=${form.code},status=published`;
        let publishedForm = await this.formService.getFormByFilter(filterStr);
        console.log("published form", publishedForm)
        if(publishedForm){
          let result = this.formService.deleteForm(publishedForm);
          console.log(' published form deletion response ------> ', result);
        }
      }
      if(deleteResponse){
        this.spinner = false;
        this.snackBarObj = {
          snackBarMessage: 'Form Deleted Successfully',
          snackBarIcon: 'check_circle',
          snackBarDuration: 2000,
          snackBarError: false,
        };
        this.openSnackBar(this.snackBarObj);
      }
      return deleteResponse;

    } catch (error) {
      console.log("could not delete form", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Form',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      throw error
    }
  }

  async deleteStarch(starch){
    try {
      let deleteResponse = await this.starchService.deleteStarchBase(starch);
      console.log('starch deletion response ------> ', deleteResponse);
        if(deleteResponse){
          this.spinner = false;
          this.snackBarObj = {
            snackBarMessage: 'Starch Deleted Successfully',
            snackBarIcon: 'check_circle',
            snackBarDuration: 2000,
            snackBarError: false,
          };
          this.openSnackBar(this.snackBarObj);
        }
        return deleteResponse;

    } catch (error) {
      console.log("could not delete starch", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Starch',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      throw error
    }
  }
}
