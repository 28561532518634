import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { WidgetManager } from '../../models/WidgetManager';

@Injectable({
  providedIn: 'root'
})
export class ListPanelService {
  currentViewType: any;

  paginationBackup: any
  filtersBackup: any = {}
  sortBackup: any[] = []

  constructor(
    public http: HttpClient,
    private _ngZone: NgZone,
    private router: Router,
    public tokenUtil: TokenUtil
  ) { };

  subPanelMeta: any = {};
  subPanelMetas: any = [];
  builderMode: boolean = false

  setFiltersBackup(filters, panelId){
    console.log("setting filters in backup", filters)
    this.filtersBackup[panelId] = filters
  }

  setSortBackup(sort){
    console.log("setting sort in backup", sort)
    this.sortBackup = sort
  }
  setPaginationBackup(page){
    console.log("setting page in backup", page)
    this.paginationBackup = page}
  getFilterBackup(panelId){ return this.filtersBackup[panelId] || [] }
  getSortBackup(){ return this.sortBackup }
  getPaginationBackup(){ return this.paginationBackup }
  resetPaginationBackup(){ this.paginationBackup = undefined }
  resetFiltersBackup(){ this.filtersBackup = {} }
  resetSortBackup(){ this.sortBackup = [] }

  createSubPanelMeta(data) {

    let panelmeta: any = {
      listAttributes: [],
      type: "listpanel",
      viewTypes: {
        defaultView: "table"
      }
    }

    let dataHeader = data[0];
    console.log("dataHeader", dataHeader)
    for(let key in dataHeader){

      let listAttMap = {};
      listAttMap['__id'] = key;
      listAttMap['name'] = key;
      listAttMap['dataType'] = "string";
      listAttMap['filterable'] = false;
      listAttMap['sortable'] = false;
      listAttMap['sortEnabled'] = false;
      listAttMap['show_hide'] = false;
      listAttMap['fieldType'] = 'attribute';
      listAttMap['widgetType'] = 'label';
      listAttMap['isColumnSelected'] = true;

      panelmeta.listAttributes.push(listAttMap);
    }

    console.log("panelmeta.listAttributes", panelmeta.listAttributes)

    panelmeta.listWidgetSet = this.generateWidgetSet(panelmeta.listAttributes, panelmeta, panelmeta.connectionId || "", panelmeta.boxId || "", panelmeta.boxObjectId || "");

    return panelmeta;
  }

  // generateWidgetSet(listAttributes){
  //   console.log("generate widget set hit", listAttributes)
  //   let newWidgetSet: any = {}
  //   let oldWidgetSet: any =  {}

  //   let oldWidgetSetValidity: boolean = true

  //   for (let i = 0; i < listAttributes.length; i++) {
  //     let widgetType: string = listAttributes[i].widgetType == 'NA' ? listAttributes[i].fieldType : listAttributes[i].widgetType;
  //     const attrId = listAttributes[i].__id;
  //     if( oldWidgetSetValidity && oldWidgetSet[attrId] && oldWidgetSet[attrId].type == widgetType){
  //       console.log("copying old widget prototype", listAttributes[i])
  //       newWidgetSet[attrId] = oldWidgetSet[attrId]
  //     }else{
  //       console.log("creating new widget prototype", listAttributes[i])
  //       newWidgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
  //     }
  //   };
  //   console.log("widget set prepared", newWidgetSet)
  //   return newWidgetSet
  // }

  generateWidgetSet(listAttributes: any, panelMetaOld: any, selectedConnectionId?: string, selectedBoxId?: string, selectedBoxObjectId?: string, isInputData?: boolean){
    // console.log("generate widget set hit", listAttributes)
    let newWidgetSet: any = {}
    let oldWidgetSet: any = panelMetaOld.listWidgetSet || {}

    let oldWidgetSetValidity: boolean = true
    // console.log("connectionId: old", this.panelMetaOld.connectionId, "new", this.selectedConnectionId)
    // console.log("boxId: old", this.panelMetaOld.boxId, "new", this.selectedBoxId)
    // console.log("boxObjectId: old", this.panelMetaOld.boxObjectId, "new", this.selectedBoxObjectId)
    if(
      !isInputData &&
      (selectedConnectionId !== panelMetaOld.connectionId ||
      selectedBoxId !== panelMetaOld.boxId ||
      selectedBoxObjectId !== panelMetaOld.boxObjectId)
    ){
      oldWidgetSetValidity = false
      // console.log("oldWidgetSet invalid")
    }

    for (let i = 0; i < listAttributes.length; i++) {
      let widgetType
      // console.log("attribute under watch", listAttributes[i])
      if (!listAttributes[i].isDrillDown) {
        // console.log("not drill down")
        widgetType = listAttributes[i].widgetType == 'NA' ? listAttributes[i].fieldType : listAttributes[i].widgetType;
        const attrId = listAttributes[i].__id;
        if (
          oldWidgetSetValidity &&
          oldWidgetSet[attrId] &&
          oldWidgetSet[attrId].type == widgetType
        ) {
          // console.log("copying old widget prototype", this.listAttributes[i])
          newWidgetSet[attrId] = oldWidgetSet[attrId]
        } else {
          // console.log("creating new widget prototype", this.listAttributes[i])
          newWidgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
        }
      } else if (listAttributes[i].isDrillDown && listAttributes[i].nestedProperties.length) {
        // console.log("drill down")
        let attr = listAttributes[i]
        for (let i = 0; i < attr.nestedProperties.length; i++) {
          const nestedProp = attr.nestedProperties[i];
          widgetType = nestedProp.widgetType || 'label'
          let nestedId = attr.__id + '.' + nestedProp.path

          if (
            oldWidgetSetValidity &&
            oldWidgetSet[nestedId] &&
            oldWidgetSet[nestedId].type == widgetType
          ) {
            // console.log("copying old widget prototype", this.listAttributes[i])
            newWidgetSet[nestedId] = oldWidgetSet[nestedId]
          } else {
            // console.log("creating new widget prototype", this.listAttributes[i])
            newWidgetSet[nestedId] = WidgetManager.getWidget(nestedProp.widgetType || 'label')
          }
        }
      }
    };
    // console.log("widget set prepared", newWidgetSet)
    return newWidgetSet
  }

  processSubData(records: any, meta?:any) {
    console.log("process data hit", records)
    let primaryAttributeId: string = meta.panelMeta.primaryAttribute ? meta.panelMeta.primaryAttribute.__id : ''

    let groupAttrId: string
    if(meta.panelMeta.viewTypes.boardStatusColumn && meta.panelMeta.viewTypes.boardStatusColumn.__id){
      groupAttrId = meta.viewTypes.boardStatusColumn.__id
    }
    // console.log("grouping attribute", groupAttrId)
    let tableData: any = []
    records.forEach(record => {
      let tableRow: any = {}
      if(primaryAttributeId){
        tableRow['__recordId'] = record[primaryAttributeId]
      }
      // console.log("record", record)
      // console.log("grouping value", record[groupAttrId])
      if(groupAttrId){
        tableRow[groupAttrId] = {value: record[groupAttrId], type: 'grouping'}
      }

      meta.panelMeta.listAttributes.forEach(attrObj => {
        if(attrObj.fieldType == 'attribute'){
          tableRow[attrObj.__id] = {value: record[attrObj.__id], type: 'attribute', widgetType: attrObj.widgetType}
          // if(attrObj.widgetType == 'image'){
          //   tableRow[attrObj.__id]['imageDimension'] = attrObj.imageDimension || this.DEFAULT_IMAGE_SIZE
          // }

        }else if(attrObj.fieldType == 'label'){
          tableRow[attrObj.__id] = {value: attrObj.value, type: 'label', columnName: attrObj.columnName, widgetType: 'label'}

        }else if(attrObj.fieldType == 'icon'){
          tableRow[attrObj.__id] = {value: attrObj.value, type: 'icon', columnName: attrObj.columnName, widgetType: 'icon'}
        }
      });
      tableData.push(tableRow)
    });

    meta.readyTableData = tableData;
    console.log("ready table data", meta)
  }

  handleSubListView(meta){
    console.log("list view settings", meta.panelMeta.viewTypes)
    this.currentViewType = meta.panelMeta.viewTypes.defaultView
    let viewCount = 0
    if(!meta.panelMeta.viewTypes.userCanChoose){
      meta.multiView = false
    }else{
      meta.panelMeta.viewTypes.views.forEach(view => {
        if(meta.viewTypes[view].userCanChoose){
          viewCount++
        }
      });
      if(viewCount > 1) meta.multiView = true
    }
  }



}
