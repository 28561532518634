
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenUtil } from '../core/services/TokenUtil.service';
import { AuthServiceService } from '../shared/services/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '../modules/organization/connection.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { WidgetManager } from '../bloom/models/WidgetManager';
import { StarchService } from '../shared/services/starch.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  currentTheme: any;
  selectedPage: any;
  isPreview: boolean;
  isPreviewSuccess: boolean;
  constructor(
    private TokenUtil: TokenUtil,
    private authService: AuthServiceService,
    private http: HttpClient,
    private connectionService: ConnectionService,
    public starchService: StarchService
  ) { }


  inputTypeWidgets:any = ['input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 'select', 'choice', "chips", "autocomplete", 'numberinput' ]

  userMadeChanges = new BehaviorSubject<boolean>(false);
  $userMadeChanges = this.userMadeChanges.asObservable()

  closeContextMenuRequest: any = new Subject()

  pageSelectionRequest = new Subject<any>();
  pageSelectionotification = this.pageSelectionRequest.asObservable()

  formMeta = new BehaviorSubject<any>('');
  get_formMeta = this.formMeta.asObservable();
  builderMode: boolean = false;
  themeSubject = new BehaviorSubject<any>({});
  capturedScreenshots: any = {
    banner: "",
    screenshots: {}
  }

  isInputWidget(widgetType: any){
    return this.inputTypeWidgets.includes(widgetType)
  }

  createFormPanelConfig(baseMap: any, boxObjectId: string, origin: string = 'base'){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": boxObjectId,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `Starch - ${boxObjectId} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
    }

    if(origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": baseMap.storage_box,
        "base_id":  baseMap._id
      }
      basicConfig.baseId = baseMap._id;
      basicConfig.boxConfigToken = baseMap.storage_token;
    } else {
      // basicConfig.boxId = this.boxId;
      // basicConfig.boxName = this.baseMap?.options?.box_name || "";
      // basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }

  filterAttributesForForm(boxAttributeRes){
    let attributes = [];
    boxAttributeRes.forEach(element => {
      if(!element.parent && !element.hasOwnProperty('writable') && ['string', 'number', 'date', 'datetime'].includes(element.dataType)){
        element['enabled'] = true;
        element.editable = true;
        element.widgetType = "input";
        if(element.primary){
          element.editable = false;
        } else if(element.relation){
          element.widgetType = "autocomplete";
        }
        attributes.push(element)
      }
    });
    return attributes;
  }

  async create_form(body?: any) {
    console.log("printing", body)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/form`
    // var url = `http://localhost:8081/api/bloom`

    try {
      let response: any = await this.http.post(url, body, options).toPromise();
      console.log("create form response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on create form:", e)
      throw e;
    }
  }


  /**
   *
   * @param code
   * @param ownerEmail
   * @param status: "published" || "draft"
   * @returns
   */
  async getFormByCode(code: any, ownerEmail?: any, status: string = "draft") {
    let token = ownerEmail ? this.connectionService.getPreAuthenticatedTokenForEmail(ownerEmail) : this.connectionService.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}`, "Cache-Control": 'no-cache' } }

    let filter = `code=${code}|string,status=${status}|string`;

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}`
    try {
      console.log("get form by code, url:", url, "options", options)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get form by code, response ---->", response);
      return response?.data[0] || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get form by code:", e)
      throw e;
    }
  }

  async getFormByFilter(filter:any, ownerEmail?: any) {
    let token = ownerEmail ? this.connectionService.getPreAuthenticatedTokenForEmail(ownerEmail) : this.connectionService.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}`, "Cache-Control": 'no-cache' } }
    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}`
    try {
      console.log("get form by filter, url:", url, "options", options)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get form by filter, response ---->", response);
      return response?.data[0] || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get form by filter:", e)
      throw e;
    }
  }


  /**
   *
   * @param wid
   * @param pageNumber
   * @param pageSize
   * @param status: draft | published
   * @returns
   */
  async getFormsByWorkspace(wid: any, pageNumber: number = 1, pageSize: number = 100, status: string = '') {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let filter = "workspace_id=" + wid + "|string"

    if(status) filter += `,status=${status}`

    let page = `${pageNumber}|${pageSize}|100`
    // let sort = "modified_at=DESC"
    let sort = ""

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}&page=${page}`
    if(sort?.length) url += `&sort=${sort}`
    // var url = `http://localhost:8081/api/bloom?filter=${filter}&sort=${sort}&page=${page}`

    try {
      console.log("hitting url", url)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get forms by workspace ID, response ---->", response);
      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on get forms by workspace:", e)
      throw e;
    }
  }

  async getFormsTemplate(pageNumber: number = 100,  pageSize: number = 1) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let filter = "template_status=published|string,template=true|boolean"

    let page = `${pageNumber}|${pageSize}|100`
    let sort = ""

    var url = `${environment.SERVER_BASE_URL}/form?filter=${filter}&page=${page}`
    if(sort?.length) url += `&sort=${sort}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get forms template, response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on get forms by workspace:", e)
      throw e;
    }
  }


  async updateForm(data?: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/form`;
    if(!data) data = this.formMeta.value
    console.log("update form payload", data)
    try {
      let response: any = await this.http.put(url, data, options).toPromise();
      console.log("update response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on update form:", e)
      throw e;
    }
  }


  async deleteForm(form?: any) {
    console.log("printing in delete form", form)

    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/form/${form._id}`
    // var url = `http://localhost:8081/api/bloom/${bloom._id}`

    try {
      let response: any = await this.http.delete(url, options).toPromise();
      console.log("delete response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on form delete:", e)
      throw e;
    }
  }

  getSubmitButton(page: any, form?: any){
    console.log("page in", page)
    let panels = page.panels;
    let formPanel = this.getFormPanel(form || page);
    let result:any = {
      widget: null
    }
    for (let index = 0; index < panels.length; index++) {
      const element = panels[index];
      if(element.type != 'regular') continue;
      else {
        result.panelId = element.id;
        for (let i = 0; i < element?.layoutMap?.list?.length; i++) {
          let columnId = element.layoutMap.list[i];
          let column = element.layoutMap[columnId];
          result.layoutId = columnId;
          for (let j = 0; j < column?.list?.length; j++) {
            let rowId = column.list[j];
            let row = column[rowId];
            result.layoutRowId = rowId;
            for (let k = 0; k < row?.elements?.length; k++) {
              let ele = row.elements[k];
              if(ele.type == "button" && ele.id == formPanel?.submitButtonMeta?.id) {
                result.widget = ele;
                result.widgetId = element.id;
                break;
              }
            }
            if(result.widget) break;
          }
          if(result.widget) break;
        }
      }
      if(result.widget) break;
    }
    return result;
  }

  getFormPanel(form){
    let panels = form.panels;
    console.log("form", form, panels)
    let result;
    for (let index = 0; index < panels.length; index++) {
      const element = panels[index];
      if(element.type == "formpanel"){
        result = element;
        break;
      }
    }
    return result;
  }

  createEmptyFormPanel(){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": "",
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `New - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "submitDecoupled": true,
      "hideTitle": true
    }
    return basicConfig;
  }

  createEmptyFormPanelButton() {
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
        "event": "click",
        "action": "application",
        "actionMap": {

          "mapping": [],
          successMessage: `Form data is successfully submitted!`
        }
      }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = "Save";
    return buttonWidget;
  }

  async prepareBase(code: string, defaultAttributes?: any){
    let base: any

    // get workspace and pick up default base id
    let workspace: any = await this.getWorkspace()
    console.log("current workspace", JSON.parse(JSON.stringify(workspace || {})))
    if(workspace.default_base_id) {
      // if default base id
      base = await this.getBase(workspace.default_base_id)
    }

    if(!workspace.default_base_id || !base) {
      // if no default base id, create a base, attach it to workspace
      console.log("will create new base")
      base = await this.createNewBase()
      console.log("new base created", base)

      // attach newly created base id to workspace
      workspace['default_base_id'] = base._id
      console.log("default base attached to workpace", workspace)

      // update workspace
      let res = await this.connectionService.updateWorkspace(workspace)
      console.log("workspace updated", res)
    }

    // create base object
    let baseObject = await this.starchService.createBaseObject(base, {object: code})
    console.log("base object created", baseObject)

    let baseAttributes: any = [
      {
        name: 'Name',
        dataType: 'string',
        defaultValue: ''
      },
      {
        name: 'City',
        dataType: 'string',
        defaultValue: ''
      },
      {
        name: 'Country',
        dataType: 'string',
        defaultValue: ''
      }
    ];

    if(defaultAttributes){
      baseAttributes = [];
      defaultAttributes.forEach(element => {
        if(!element.primary) baseAttributes.push({
          name: element.__id,
          dataType: element.dataType,
          defaultValue: ''
        })
      });
    }


    // create base object attribute
    let payload = {
      attributes: baseAttributes,
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attrCreateResponse = await this.starchService.createBaseAttributes(base, payload)
    console.log("attribute created", attrCreateResponse)

    // get attributes
    let getAttrPayload = {
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attributes = await this.starchService.getBaseAttributes(base, getAttrPayload)
    console.log("attributes fetched", attributes)

    // this.spinnerService.hide();
    return {
      baseMap: base,
      object: baseObject,
      attributes: attributes
    }
  }

  getWorkspace(){
    return this.connectionService.selectedWorkSpaceMap
  }

  async getBase(id: string){
    let base = await this.starchService.getStarchBase(id)
    return base
  }

  async createNewBase(){
    console.log("create new base hit")
    let randomCode: string = await this.starchService.checkAndGetRandomCode()
    let baseMap = {
      code: randomCode,
      name: randomCode
    }
    // this.spinnerService.show();
    let base = await this.starchService.createStarchBase(baseMap);
    console.log("base created", base)
    return base
  }

  getLogoUrl() {
    let randomLogoCode: any = Math.floor(Math.random() * 10)
    randomLogoCode = randomLogoCode + 1
    if (randomLogoCode < 10) {
      randomLogoCode = '0' + randomLogoCode
    }

    let logoUrl = environment.BLOOM_LOGO_BASE_URL + randomLogoCode + '.jpg'
    return logoUrl
  }

  getImageUrl(type: string) {
    let randomCode: any = Math.floor(Math.random() * 6)
    randomCode = randomCode + 1

    let imageUrl
    if(type == 'logo'){
      // imageUrl = environment.FORM_LOGO_BASE_URL + randomCode + '.jpg'
    }else if(type == 'banner'){
      imageUrl = environment.FORM_BANNER_BASE_URL + randomCode + '.png'
    }
    return imageUrl
  }

  createFormMeta(formNameMap?: any){
    let randomCode = String(Date.now()).substring(8);

    let logoUrl = this.getLogoUrl()
    console.log("logo url", logoUrl)

    console.log("random code", randomCode)
    let form = {
      code: formNameMap?.code || 'form-' + randomCode,
      name: formNameMap?.name || 'form-' + randomCode,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      created_by: this.authService.profile.email,
      modified_by: this.authService.profile.email,
      workspace_id: this.connectionService.selectedWorkSpace,
      logoUrl: logoUrl,
      status: 'draft',
      access_type:  "private",
      require_login: true,
      title: "New Form",
      tagline: "Customizable form based on starch"
    };
    return form
  }

  createFormPanel(baseMap: any, object: string){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": object,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `${object} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "submitDecoupled": true,
      "hideTitle": true
    }

    basicConfig.boxId = "starch";
    basicConfig.baseMap = {
      "box_id": baseMap.storage_box,
      "base_id": baseMap._id
    }
    basicConfig.baseId = baseMap._id;
    basicConfig.boxConfigToken = baseMap.storage_token;

    return basicConfig;
  }

  formPanelButton(baseMap: any, object: string){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
        "event": "click",
        "action": "application",
        "actionMap": {
          "action": `${object}/create`,
          "boxId": "starch",
          "actionMode": "save",
          "boxConfigToken": baseMap.storage_token,
          "baseMap": {
            "box_id": baseMap.storage_box,
            "base_id":  baseMap._id
          },
          "mapping": [],
          successMessage: `Form data is successfully submitted!`
        }
      }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = "Save";
    return buttonWidget;
  }

  createNavAction(){
    return {
      "event": "click",
      "action": "navigation",
      "formSuccessAction": true,
      "actionMap": {
        "mapping": [],
        "parameters": [],
        "pageType": "form_success"
      }
    }
  }

}
