<div
  *ngIf="builderMode"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle *ngIf="widgetOptions?.dragDrop !== false" [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  
  <ng-container *ngIf="!isMulti; else elseTemplate">

    <!-- valid src -->
    <div [class.shine]="isLoading" *ngIf="imgSrc" class="imageWrap" [style.text-align]="widgetMeta.config.imageAlignment?.value || 'center'">
      <img
        #img
        [ngStyle]="localStyles"
        class="image"
        alt="image here"
        [src]="imgSrc"
        (load)="imageLoad()"
      >
    </div>

    <!-- empty src -->
    <div *ngIf="!imgSrc" class="app" fxLayoutAlign="center center">
      <img 
        class="placeholder-image"
        [ngStyle]="localStyles"
        [ngClass.lt-md]="'placeholder-sm'" [ngClass.md]="'placeholder-md'" [ngClass.gt-md]="'placeholder-lg'"
        [src]="no_image_placeholder_url" alt="Image not available">
    </div>

    <!-- default src before value assignment -->
    <!-- <div *ngIf="imgSrc === '__default__'" class="app">
      <img 
        class="placeholder-image imageIcon"
        [ngStyle]="localStyles"
        [ngClass.lt-md]="'placeholder-sm'" [ngClass.md]="'placeholder-md'" [ngClass.gt-md]="'placeholder-lg'"
        [src]="placeholder_url" alt="Image">
    </div> -->
  </ng-container>

  <ng-template #elseTemplate>
    <div class="gridWrap" fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap=".2rem">
      <ng-container *ngFor="let src of srcArr; let i = index">
        <div
          fxFlex.xs="90" fxFlex.sm="45" fxFlex.md="30" fxFlex.lg="18" fxFlex.xl="15"
          class="memberImageWrap imageWrap"
        >
          <img
            #img
            [ngStyle]="localStyles"
            *ngIf="src && builderMode"
            class="image memberImage"
            alt="image here"
            [src]="src"
            (load)="imageLoad(i)"
          >
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>



<div
  *ngIf="!builderMode"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <ng-container *ngIf="!isMulti; else elseTemplate">
    <div [class.shine]="isLoading" *ngIf="imgSrc" class="imageWrap">
      <img
        #img
        [ngStyle]="localStyles"
        class="image"
        alt="image here"
        [src]="imgSrc"
        (load)="imageLoad()"
      >
    </div>

    <!-- empty src -->
    <div *ngIf="!imgSrc" class="app" fxLayoutAlign="center center">
      <img 
        class="placeholder-image"
        [ngStyle]="localStyles"
        [ngClass.lt-md]="'placeholder-sm'" [ngClass.md]="'placeholder-md'" [ngClass.gt-md]="'placeholder-lg'"
        [src]="no_image_placeholder_url" alt="Image not available">
    </div>
  </ng-container>

  <ng-template #elseTemplate>
    <div class="gridWrap" fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap=".2rem">
      <ng-container *ngFor="let src of srcArr; let i = index">
        <div
          fxFlex.xs="90" fxFlex.sm="45" fxFlex.md="30" fxFlex.lg="18" fxFlex.xl="15"
          class="memberImageWrap imageWrap"
        >
          <img
            #img
            [ngStyle]="localStyles"
            *ngIf="src"
            class="image memberImage"
            alt="image here"
            [src]="src"
            (load)="imageLoad(i)"
          >
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
