import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service'
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
// import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BoxInstance } from 'src/app/core/boxInstances/BoxInstance';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { Panel } from 'src/app/bloom/models/panelClasses/basePanel';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';

@Injectable({
  providedIn: 'root'
})

export class PublishService {

  viewTypes = {
    "views": [
        "table",
        "card",
        "board"
    ],
    "defaultView": "table",
    "boardStatusColumn": "",
    "userCanChoose": true,
    "table": {
        "name": "table",
        "displayName": "Table",
        "userCanChoose": true,
        "icon": "list"
    },
    "card": {
        "name": "card",
        "displayName": "Card",
        "userCanChoose": true,
        "icon": "grid_on"
    },
    "board": {
        "name": "board",
        "displayName": "Board",
        "userCanChoose": false,
        "icon": "view_kanban"
    }
  }

  constructor(
    private http: HttpClient,
    public connectionService: ConnectionService,
    public authService: AuthServiceService,
    public clientPlatformService: ClientPlatformService
  ) { }


  async publishList(listPanelMeta: any, bloomName?: string, product?: string) {
    console.log("publish hit: list panel meta received", listPanelMeta)

    let page_meta = {
        code: listPanelMeta.boxObjectId.split(" ").join("_") + "-listing",
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        panels: [listPanelMeta]
    }

    var payload = {
      page_meta: page_meta,
      user_details: {
        email: this.authService.getUserEmail() || this.clientPlatformService.userEmail
      },
      workspace_id: await this.connectionService.getWorkSpaceId() || this.clientPlatformService.workSpaceId,
      product: product,
      bloom_name: bloomName,
      publish_source: {
        type: "published_list", // "published_list" | "published_form" | ""
        connection_id: listPanelMeta.connectionId,
        box_object: listPanelMeta.boxObjectId
      }
    }
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let url = environment.SERVER_BASE_URL + '/publish'
    console.log("url", url)
    console.log("payload", payload)
    console.log("options", options)
    try {
      let response: any = await this.http.post(url, payload, options).toPromise();
      console.log("[PUBLISH SERVICE] publish response:", response);
      let result = response || null;
      return result;
    } catch (e) {
      console.error("[PUBLISH SERVICE] Error on publish:", e)
      throw e;
    }
  }

//   delay = (delayInms) => {
//     return new Promise(resolve => setTimeout(resolve, delayInms));
//   };

  generateListPanelMeta(data: any){
    console.log("data to create list", data)
    let panelMeta = new Panel(Date.now(), `${data.boxObjectId} listing`)
    console.log("panelMeta created", JSON.parse(JSON.stringify(panelMeta)))
    let otherProps = {
      type: "listpanel",
      alignment: "flex-start",
      layoutCount: "1",
      // boxId: data.boxId,
      // connectionId: data.connection._id,
      boxObjectId: data.boxObjectId,
      listAttributes: this.constructListAttributes(data.attributes),
      listPanelTitle: data.listPanelTitle,
      // pageSize: 20,
      loadInitialData: true,
      dataSource: "app",
      paginationEnabled: true,
      paginationType: "pagebypage",
      defaultListSize: data.pageSize || 10,
      maxPerPageLimit: data.maxPerPageLimit,
      noDataMessage: "There is no data to be shown here",
      viewTypes: data.viewTypes || this.viewTypes,
    }
    if(data.connection) {
      otherProps['connectionId'] = data.connection._id
      otherProps['boxId'] = data.boxId
    }
    if(data.base) {
      otherProps['baseId'] = data.base._id
      otherProps['baseMap'] = {
        base_id: data.base._id,
        box_id: data.base.storage_box
      }
      otherProps['boxId'] = 'starch'
    }

    if(data.filter) otherProps['filter'] = data.filter
    if(data.boxConfigToken) otherProps['boxConfigToken'] = data.boxConfigToken
    if(data.sort) otherProps['sort'] = data.sort
    if(data.hideTitle) otherProps['hideTitle'] = data.hideTitle
    if(data.userFilterEnabled) otherProps['userFilterEnabled'] = data.userFilterEnabled
    if(data.userSortEnabled) otherProps['userSortEnabled'] = data.userSortEnabled
    if(data.getFnOptions) otherProps['getFnOptions'] = data.getFnOptions
    if(data.attributeOptions) otherProps['attributeOptions'] = data.attributeOptions
    if(data.primaryAttribute) otherProps['primaryAttribute'] = data.primaryAttribute
    panelMeta = Object.assign(panelMeta, otherProps)
    if(data.canSelectFields) panelMeta['viewTypes'].canSelectFields = data.canSelectFields
    panelMeta['listWidgetSet'] = this.generateListWidgetSet(panelMeta),
    console.log("list panel created", panelMeta)
    return panelMeta
  }

  constructListAttributes(attributes: any[] = []){
    attributes.forEach(attr => {
      let widgetType = attr.widgetType || 'label';
      let fieldType = attr.fieldType || 'attribute';

      if (attr.__id == '__checkbox__') {
        widgetType = 'checkbox';
        fieldType = 'checkbox';
      }

      attr['isColumnSelected'] = (attr.hasOwnProperty('isColumnSelected')) ? attr.isColumnSelected : true;
      attr['widgetType'] = widgetType,
      attr['fieldType'] = fieldType;
    })
    return attributes
  }

  generateListWidgetSet(panelMeta: any){
    console.log("panelMeta", panelMeta)
    console.log("listAttributes", panelMeta.listAttributes)
    let widgetSet: any = {}
    for (let i = 0; i < panelMeta.listAttributes.length; i++) {
      let widgetType = panelMeta.listAttributes[i].widgetType == 'NA' ? panelMeta.listAttributes[i].fieldType : panelMeta.listAttributes[i].widgetType;
      const attrId = panelMeta.listAttributes[i].__id;
      widgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
    };
    console.log("list widget set prepared", widgetSet)
    return widgetSet
  }

  /**
   * 
   * {
    "type": "listpanel",
    "alignment": "flex-start",
    "layoutCount": "1",
    "widgets": [],
    "id": 1707454569260,
    "name": "listPanel_1",
    "layoutMap": {
        "list": [
            1707454569261
        ],
        "1707454569261": {
            "gridX": 12,
            "list": [
                1707454570260
            ],
            "1707454570260": {
                "type": "elements",
                "elements": []
            }
        }
    },
    "connectionId": "6119fe797bbe44155ded1ad2",
    "boxName": "mongo_one",
    "boxId": "mongodb",
    "boxObjectId": "flow",
    "listAttributes": [],
    "aditionalAttributesAllowed": false,
    "pageSize": 20,
    "loadInitialData": true,
    "dataSource": "app",
    "paginationEnabled": true,
    "paginationType": "pagebypage",
    "defaultListSize": 10,
    "noDataMessage": "There is no data to be shown here",
    "boxConfigToken": "U2FsdGVkX1+YKGQVLP/sMZNyhB2H1116Zwok2ksvHSrmtT5/HIek4GqdKAaYhj2K37Ldi8fuleCPHRDKdk8xbmaApjQoHi/tYCLegSluRkkotRDb7QkC78yAlJkg9mZgJ3kkeEFZt+aFGPaFYM6lesFTjPbIBYaJ/J018mYpHuCeEnRaT4T4AveslhOLxt7tfsgO5+MFbnIRKtESpP46SL6q//2F2o8LbnA2dnKmf4e/SYB86F4MiOh4gYmDr+mycjZHh/jAY/yQoCdTjt5qTa3Y5h/OOXI+Nj1lugXMKX5ilbufI2ZIxVQ8Ui9dWUAi",
    "attributeOptions": [],
    "getFnOptions": [],
    "listPanelTitle": "mongo_one - flow - Listing",
    "viewTypes": {
        "views": [
            "table",
            "card",
            "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
        },
        "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
        },
        "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
        }
    },
    "primaryAttribute": {},
    "filter": {
        "filterEnabled": false,
        "filterItems": []
    },
    "sort": {
        "sortEnabled": false,
        "sortAttributes": []
    },
    "listWidgetSet": [],
    "getFn": {
        "__id": "get",
        "name": "Get",
        "dataFlow": "pull",
        "crudType": "R",
        "functionName": "get",
        "options": {
            "filter": true,
            "sort": true,
            "maxPageSize": 1000
        },
        "input": {
            "list": [
                "query",
                "options"
            ],
            "options": {
                "name": "options",
                "dataType": "object",
                "list": [
                    "relationObject"
                ],
                "relationObject": {
                    "dataType": "string",
                    "name": "Relation Object",
                    "__id": "relationObject",
                    "hidden": true
                }
            },
            "query": {
                "name": "query",
                "dataType": "query",
                "required": true
            }
        }
    },
    "selectedNow": false
}
   */


}
