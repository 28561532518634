<div>
  <i class="map-container info" *ngIf="mappingToggle && allMapping.length === 0">
    You do not have any mapping as of now. Please click the below <b>+ Add</b> button to
    create new mapping.
  </i>
</div>

<mat-list *ngFor="let mapObj of allMapping; index as i">
  <mat-list-item>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" class="map-list">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <b>{{ i + 1 }}.&nbsp;</b>
          <img *ngIf="appInfo?.logo?.url" [src]="appInfo?.logo?.url" [attr.height]="appInfo?.logo?.height || 20" />
          <b [matTooltipDisabled]="!isSmallScreen || (mapObj.appField?.name?.length <= 10) || (mapObj.appField?.__id?.length <= 10)" [matTooltip]="mapObj.appField.name">
            &nbsp;{{ ((isSmallScreen) ? (mapObj.appField.name||mapObj.appField.__id | truncate: [10]) : mapObj.appField.name||mapObj.appField.__id) }}
            <span *ngIf="mapObj.appField.required" class="warn" matTooltip="Required">*</span>
          </b>
        </div>
        <div class="mapping-icon">
          <mat-icon color="primary">sync_alt</mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-icon>{{mapObj.mappingType === "templateField" ? "description" : mapObj.appField.dataType === 'array' ? "view_list" : "text_fields"}}</mat-icon>
          <span [matTooltipDisabled]="mapObj?.mappingType === 'templateField' || !isSmallScreen || (mapObj.sourceField?.name?.length <= 10)" [matTooltip]="mapObj.sourceField?.name">
            {{ showMappingType(mapObj) }}&nbsp;
          </span>
          <img *ngIf="sourceInfo?.logo?.url" [src]="sourceInfo?.logo?.url" [attr.height]="sourceInfo?.logo?.height || 20" />
        </div>
        <div *ngIf="mapObj.sourceField?.referenceField && !mapObj.sourceField.referenceField.hidden" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between center">
          <div class="mapping-icon">
            <mat-icon color="primary">east</mat-icon>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon>text_fields</mat-icon>
            <span [matTooltipDisabled]="!isSmallScreen || (mapObj.sourceField?.referenceField.name.length <= 10)" [matTooltip]="mapObj.sourceField?.referenceField.name">
              {{ mapObj.sourceField?.referenceField.name }}&nbsp;
            </span>
            <img *ngIf="sourceInfo?.logo?.url" [src]="sourceInfo?.logo?.url" [attr.height]="sourceInfo?.logo?.height || 20" />
          </div>
        </div>
      </div>

      <div>
        <button
          color="primary"
          matTooltip="Edit"
          mat-icon-button
          (click)="mappingToggle = !mappingToggle; editMappingField(mapObj, i)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          color="warn"
          mat-icon-button
          [disabled]="mapObj.appField.required"
          matTooltip="Delete"
          (click)="deleteMappingField(i)"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
<br />

<div fxLayout="row" fxLayoutAlign="start center">


<button
  *ngIf="mappingToggle"
  class="map-add-plus-btn"
  matTooltip="Click to add mapping."
  mat-raised-button
  color="primary"
  (click)="mappingToggle = !mappingToggle;onNewMapping()"
>
  <mat-icon>add_circle</mat-icon> Add
</button>

<button
  *ngIf="mappingToggle"
  class="map-add-plus-btn"
  matTooltip="Automatic mapping"
  mat-icon-button
  color="primary"
  (click)="checkForAutoMapping('manual')"
>
  <mat-icon>wifi_protected_setup</mat-icon>
</button>
</div>
<mat-card appearance="outlined" *ngIf="!mappingToggle">
  <mat-card-content>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex="95">
      <mat-label>{{ appInfo?.app || 'App' }} {{appInfo?.object?.name || appInfo?.action}} field</mat-label>
      <mat-icon
        style="vertical-align: middle"
        *ngIf="selectedAppField.value"
        [matTooltip]="selectedAppField.value.dataType"
        matPrefix
        >{{ appFieldIconMap[selectedAppField.value.dataType] }}</mat-icon
      >
      <img
        matSuffix
        [matTooltip]="boxConn?.box_id || appInfo?.app"
        *ngIf="selectedAppField.value"
        class="box-img"
        [src]="boxConn?.options?.box_logo_url || appInfo?.logo?.url"
        [attr.height]="appInfo?.logo?.height || 20"
        alt=""
      />
      <input
        type="text"
        aria-label="AppField"
        matInput
        matAutocompletePosition="below"
        [formControl]="selectedAppField"
        [matAutocomplete]="autoAppField"
      />

      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearAppField()"
      >
        <mat-icon>close</mat-icon>
      </button>

      <mat-autocomplete
        autoActiveFirstOption
        #autoAppField="matAutocomplete"
        [displayWith]="displayFnForAppField.bind(this)"
        (optionSelected)="onSelectionOfAttr($event.option.value)"
      >
        <mat-option>-- Reset Selection --</mat-option>
        <mat-option
          *ngFor="let attr of filteredObjectAttributes | async"
          [value]="attr"
        >
          <mat-icon [matTooltip]="attr.dataType">{{
            appFieldIconMap[attr.dataType]
          }}</mat-icon>
          <span>{{ attr.name || attr.__id }}</span>
          <img
            *ngIf="boxConn?.options?.box_logo_url || appInfo?.logo?.url"
            class="box-img"
            [src]="boxConn?.options?.box_logo_url || appInfo?.logo?.url"
            [attr.height]="appInfo?.logo?.height || 20"
            alt=""
          />
        </mat-option>
        <mat-option *ngIf="isSupportsCustomAttribute" value="AW_CREATE_NEW_ATTRIBUTE">-- Create New --</mat-option>
      </mat-autocomplete>

    </mat-form-field>
    <!-- <button  *ngIf="selectedAppField.value"fxFlex="5" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Drill Down" matTooltip="Looking for more fields to map?"> -->
      <div fxFlex="5"> <mat-icon class="picker-icon" *ngIf="selectedAppField.value" [matMenuTriggerFor]="menu" aria-label="Drill Down" matTooltip="Looking for more fields to map?">more_vert</mat-icon> </div>
    <!-- </button> -->
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openMappingDrillDownDialog()">Drill down</button>
      <button *ngIf="isSupportsCustomAttribute" mat-menu-item (click)="openMappingAddAttrDialog()">+ Add Attribute</button>
    </mat-menu>
  </div>
  <ng-container *ngIf="isLineItemMapping; else mappingTemplate">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="10px">
          <mat-card appearance="outlined" *ngFor="let itemMapObj of itemsMappingArr[selectedAppFieldId]; index as i">
            <mat-card-content>
            <div fxLayout="column" fxLayoutGap="5px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span><strong>Item Mapping</strong></span>
                <!-- <button mat-icon-button color="warn" (click)="removeItem(i)" matTooltip="Remove Item">
                  <mat-icon>delete</mat-icon>
                </button> -->
              </div>
              <app-mapping-setup
                (mappingChange)="setMappingArr($event, i)"
                [mappingArr]="itemMapObj"
                [serviceType]="serviceType"
                [appInfo]="appInfo"
                [sourceInfo]="sourceInfo"
                [appFields]="getItemAppFields()"
                [sourceFields]="sourceFields"
              >
              </app-mapping-setup>
            </div>
          </mat-card-content>
          </mat-card>
          <!-- <button mat-stroked-button color="primary" (click)="addItem()" style="width: fit-content;">+ Add Item</button> -->
        </div>
      </mat-card-content>
    </mat-card>

  </ng-container>

  <div class="map-actions-btn">
    <button mat-raised-button color="primary" (click)="addMappingField()">
      {{ isEditEnabled ? "Update" : "Add" }}
    </button>
    <button
      style="margin-left: 5px"
      mat-stroked-button
      color="primary"
      (click)="resetMappingSettings()"
    >
      Cancel
    </button>
  </div>

  <ng-template #mappingTemplate>
    <div  fxLayout="row"> <p><b>Choose the mapping type.</b></p>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about mapping'" aria-label="Help"
      rel="noreferrer">
      <mat-icon>help</mat-icon>
    </a>
    </div>
    <div *ngIf="selectedAppField.value" style="font-size: smaller;"> How do you want to compose value for {{selectedAppField.value.name || selectedAppField.value.__id}}? </div>
    <mat-radio-group
      color="primary"
      aria-label="Select an option"
      [formControl]="selectedMappingType"
      (change)="radioChange($event)"
    >
      <mat-radio-button value="sourceField">{{ clientPlatformService.sourceApp || "Source" }} Field</mat-radio-button>
      <mat-radio-button value="templateField">Templated</mat-radio-button>
      <mat-radio-button value="conditional">Conditional</mat-radio-button>
    </mat-radio-group>

    <!-- <p style="margin-top: 10px" *ngIf="selectedMappingType.value === 'sourceField'">
      <b>Map to a {{ clientPlatformService.sourceApp || "Source" }} Field.</b>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about field mapping'" aria-label="Help"
        rel="noreferrer">
      <mat-icon>help</mat-icon>
    </a>
    </p> -->
    <!-- <p style="margin-top: 10px" *ngIf="selectedMappingType.value === 'templateField'">
      <b>Prepare value from Template.</b>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about template field mapping'" aria-label="Help"
      rel="noreferrer">
    <mat-icon>help</mat-icon>
  </a>
    </p> -->

    <div  fxLayout="row" style="margin-top: 10px" *ngIf="selectedMappingType.value === 'sourceField'">
      <p><b>Map to a {{ clientPlatformService.sourceApp || "Source" }} Field.</b></p>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about field mapping'" aria-label="Help"
        rel="noreferrer">
        <mat-icon>help</mat-icon>
      </a>
    </div>

    <div  fxLayout="row" style="margin-top: 10px" *ngIf="selectedMappingType.value === 'templateField'">
      <p><b>Prepare value from Template.</b></p>
      <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about template field mapping'" aria-label="Help"
        rel="noreferrer">
        <mat-icon>help</mat-icon>
      </a>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="selectedMappingType.value === 'sourceField'">
        <mat-form-field fxFlex="95">
          <mat-label>{{ clientPlatformService.sourceApp || sourceInfo?.app || "Source" }} {{ sourceInfo?.object || sourceInfo?.action }} field</mat-label>
          <mat-icon
            style="vertical-align: middle;"
            *ngIf="selectedSourceField.value"
            [matTooltip]="selectedSourceField.value.dataType"
            matPrefix
            >{{ sourceFieldIconMap[selectedSourceField.value.dataType] }}</mat-icon
          >
          <img
            matSuffix
            [matTooltip]="boxConn?.box_id || sourceInfo?.app"
            *ngIf="selectedSourceField.value"
            class="box-img"
            [src]="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
            [attr.height]="sourceInfo?.logo?.height || 20"
            alt=""
          />
          <input
            type="text"
            aria-label="SourceField"
            matInput
            matAutocompletePosition="below"
            [formControl]="selectedSourceField"
            [matAutocomplete]="autoSourceField"
          />
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSourceField()"
          >
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete
            autoActiveFirstOption
            #autoSourceField="matAutocomplete"
            [displayWith]="displayFnForSourceField"
            (optionSelected)="checkForReferenceMapping($event.option.value)"
          >
            <mat-option
              *ngFor="let sourceAttr of filteredSourceAttributes | async"
              [value]="sourceAttr"
            >
              <mat-icon [matTooltip]="sourceAttr.dataType">{{
                sourceFieldIconMap[sourceAttr.dataType]
              }}</mat-icon>
              {{ sourceAttr.name }}
              <img
                *ngif="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
                class="box-img"
                [src]="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
                [attr.height]="sourceInfo?.logo?.height || 20"
                alt=""
              />
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div fxFlex="5" >
          <mat-icon class="picker-icon" [matMenuTriggerFor]="srcMenu" aria-label="Drill Down" matTooltip="Looking for more fields to map?">more_vert</mat-icon>
        </div>
        <mat-menu #srcMenu="matMenu">
          <button mat-menu-item (click)="openSourceMappingDrillDownDialog()">Drill down</button>
        </mat-menu>
      </div>

      <div fxLayout="column">
        <div fxLayout="row" *ngIf="selectedSourceField.value?.reference && referenceFields.length">
          <mat-icon fxFlex="2">subdirectory_arrow_right</mat-icon>
          <mat-form-field fxFlex="95.3">
            <mat-label>Reference field from {{ clientPlatformService.sourceApp || sourceInfo?.app }} {{ selectedSourceField.value.reference }}</mat-label>
            <mat-icon
              style="vertical-align: middle;"
              [matTooltip]="selectedReferenceField.value.dataType"
              matPrefix
            >
              {{ sourceFieldIconMap[selectedReferenceField.value.dataType] }}
            </mat-icon>
            <img
              matSuffix
              [matTooltip]="boxConn?.box_id || sourceInfo?.app"
              *ngIf="selectedSourceField.value"
              class="box-img"
              [src]="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
              [attr.height]="sourceInfo?.logo?.height || 20"
              alt=""
            />
            <input
              type="text"
              aria-label="ReferenceField"
              matInput
              matAutocompletePosition="below"
              [formControl]="selectedReferenceField"
              [matAutocomplete]="autoReferenceField"
            />
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearReferenceField()"
            >
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete
              autoActiveFirstOption
              #autoReferenceField="matAutocomplete"
              [displayWith]="displayFnForSourceField"
            >
              <mat-option
                *ngFor="let refAttr of filteredReferenceAttributes | async"
                [value]="refAttr"
              >
                <mat-icon [matTooltip]="refAttr.dataType">{{
                  sourceFieldIconMap[refAttr.dataType]
                }}</mat-icon>
                {{ refAttr.name }}
                <img
                  *ngif="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
                  class="box-img"
                  [src]="boxConn?.options?.box_logo_url || sourceInfo?.logo?.url"
                  [attr.height]="sourceInfo?.logo?.height || 20"
                  alt=""
                />
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div fxFlex="1.7"></div>
        </div>
      </div>

      <div *ngIf="selectedMappingType.value === 'templateField'" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex>
          <mat-label>Template Message</mat-label>
          <div style="display: flex; flex-direction: row">
            <textarea
              matInput
              placeholder="e.g. Hi ${Name}"
              [formControl]="templatedValue"
            ></textarea>
          </div>
        </mat-form-field>
        <app-field-picker fxFlex="15"
          [inputFieldMap]="fieldMap"
          (selectedFields)="insertCursor($event)"
        ></app-field-picker>
      </div>

      <!-- , loadedAttributes: loadedAttributes -->
      <div *ngIf="selectedMappingType.value === 'conditional'">
        <div style="margin-top: 10px"  fxLayout="row">
            <p><b>Conditional.</b></p>
            <a *ngIf="displayOption?.showHelpLinks" mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Know about conditional mapping'" aria-label="Help"
              rel="noreferrer">
              <mat-icon>help</mat-icon>
            </a>
        </div>
        <!-- <mat-label>Conditional</mat-label> -->
        <div *ngFor="let conditionObj of conditionMap.conditionSets; index as i">
          <mat-card>
            <mat-card-content>
              <div fxLayout="row">
                <div fxFlex="90"> <b>Condition Set {{i + 1}}</b> </div>
                <mat-icon  fxFlex="10" color="warn" (click)="deleteCondition(i)">delete</mat-icon>
              </div>

              <div>
                <!-- <app-filter-config
                  [filter]="{filterEnabled: true, filterItems: conditionObj.conditions }"
                  [fieldListingMap]="{sourceFields: sourceFields, systemFields: systemFields, oldAttributes: loadedAttributes}"
                  [boxObjectAttributes]="appFields"
                  [hideToggle]="true"
                  [showAttributePicker]="true"
                  [lockFilterType]="true"
                  (selectedFilter)="filterChanged($event, conditionObj)"
                >
                </app-filter-config> -->
                <app-filter-component
                  [filter]="{filterEnabled: true, filterItems: conditionObj.conditions }"
                  [fieldListingMap]="{sourceFields: sourceFields, systemFields: systemFields, oldAttributes: loadedAttributes}"
                  [boxObjectAttributes]="appFields"
                  [hideToggle]="true"
                  [showAttributePicker]="true"
                  [lockFilterType]="true"
                  (selectedFilter)="filterChanged($event, conditionObj)"
                ></app-filter-component>
              </div>
              <div>
                <mat-form-field class="noBottomMargin">
                  <mat-label>Value</mat-label>
                  <input matInput #filterValueInput [(ngModel)]="conditionObj.resultField">
                </mat-form-field>
                <app-field-picker
                  [inputFieldMap]="fieldMap"
                  (selectedFields)="insertCursorDefault($event, conditionObj)"
                ></app-field-picker>
              </div>
            </mat-card-content>

          </mat-card>
        </div>
        <div>
          <div style="height: 1rem"></div>
          <button  mat-stroked-button color="primary" (click)="addCondition()">
            <mat-icon>add</mat-icon> Add Condition
          </button>
        </div>
        <div>
          <mat-form-field class="noBottomMargin">
            <mat-label>Value</mat-label>
            <input matInput #filterValueInput [(ngModel)]="conditionMap.defaultField">
          </mat-form-field>
          <app-field-picker
                [inputFieldMap]="fieldMap"
                (selectedFields)="insertCursorDefaultField($event, conditionMap)"
          ></app-field-picker>
        </div>
      </div>

    </div>
  </ng-template>
</mat-card-content>
</mat-card>
