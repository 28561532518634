export const environment = {
  env: "production",
  production: true,
  build_number: '3407',
  version: `${require('../../package.json').version}`,
  SERVER_BASE_URL: "https://us-central1-jcp-workspace-fly-1.cloudfunctions.net/server/api",
  BOX_URL: "https://us-central1-jcp-box-fly-1.cloudfunctions.net/server/api/box",
  DB_BOX_URL: "https://us-central1-jcp-box-fly-1.cloudfunctions.net/mongodb-server/api/box",
  DB_BOX_TOKEN: "U2FsdGVkX1/CMA3ZRX6XAthOw3XZCGIZymCc7hle/VwRun7D+wc6Y+0CkMa3R9l5RoYf/4jNyKNEtpZcDpqSVWi2FjoK7a2drb548TPFBxDe+UqkRbxF95ou4AOlYEKh9dtOw6CAJV8ksopmt4RIc736vXnO7inlS6tuDeTT1QJadQleblmR5lM7dvJxffNJ4Z1OHkWRhu1Tyr+AzJrlnsUiOjEadf5ha7AqZp4D8EiQHM0RK5igfimSaog2w5rxEfIl58zjy2uNJbs1PDJhfemxSkXwn0RwQa6W+k5vRxc=",
  GOOGLE_CLIENT: "364132581084-a2e86roqm52g1b9240pt44cc521arp5l.apps.googleusercontent.com",
  GOOGLE_SECRET: "rDI45NX2cvnAFngE59a8eA9R",
  LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/",
  FORM_BANNER_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/form/banner/",
  ASSETS_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/",
  STARCH_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/starch/logo/",
  IMAGE_PLACEHOLDER: {
    PLACEHOLDER_GENERAL: "image-placeholder.jpg",
    PLACEHOLDER_NO_IMAGE: "no-image-placeholder.jpg"
  },
  MIXPANEL_KEY: "ab5016ceb06dfdcc712810ce57bd4aac",
  GCS_BUCKET: "appiworks-bloom-storage",
  GCS_BASE_URL: "https://storage.googleapis.com",
  GCS_CONNECTION_ID: "66a8dc04202f6a16518df6f5",
  BLOOM_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/bloom/avatar/",
  HOTJAR_ID: "2817974",
  GTM_KEY: "GTM-MJKRWZW",
  BASE_URL: "https://appiworks.com",
  BASE_URL_ZOHO_BOOKS: "https://books.zoho.com/api/v3",
  BASE_URL_ZOHO_BILLINGS: "https://billing.zoho.com/api/v3",
  SES_CONNECTION_ID: "6334110681dd49122dc8347f",
  "SMS_AUTH_KEY": "fddd0981-68c2-4e8b-bf9f-87026cdadfb4",
  "SMS_SECRET_KEYS": {
      "SECRET_KEY": "fd08b9b2-3c23-4f1f-bccd-9154072f8015",
      "COMPARE_KEY": "1581bb4b-c172-43a5-864d-07a27d77b3be"
   },
  "SMS_BASE_URLS" : ["https://us-central1-sms-fly.cloudfunctions.net/server/api/", "https://sms-fly.appspot.com/api/"],
  "SMS_PORTAL_URL": "https://portal.jivrus.com/",
  INTERNAL_BLOOM: {
    ARTICLE: "article",
    INTEGRATIONS: "integrations",
    LEGAL: "legal",
    BLOOM_TEMPLATES: "bloom-templates",
    FLOW_TEMPLATES: "flow-templates",
    DEMO_BLOOMS: "demo-blooms",
    FORM_TEMPLATES: "form-templates"
  },
  BOUTIQUE_WORKSPACE: "65c1fdab0634b1e56468a306"
};
