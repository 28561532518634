<spinner *ngIf="spinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" class="heading">
  <div fxFlex="10" fxLayout fxLayoutAlign="center center"><mat-icon class="matIcon">warning</mat-icon></div>
  <div fxFlex="90">{{ data.resource }} Deletion</div>
</div>

<ng-container *ngIf="data.isAppData; else elseTemplate">
  <div style="margin-top: 1.5rem; padding: 0 0 0 1rem;">
    <span>Are you sure you want to delete {{ data.resourceName }} from {{ data.resource + " ?" }}</span>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div style="margin-top: 1.5rem; padding: 0 0 0 1rem;">
    <span>Are you sure you want to delete {{ data.resource }} </span>
    <span style="font-weight: 500;"><b>{{ data.resourceName + " ?" }}</b></span>
  </div>
</ng-template>


<div style="margin-top: 1rem; padding: 0 0 0 1rem; font-size: medium;">
  <span style="color:brown">This action cannot be reverted!</span>
</div>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close (click)="dontDelete()">Cancel</button>
  <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
</mat-dialog-actions>
