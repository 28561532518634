import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { BloomErrorDiologComponent } from 'src/app/bloom/builder/bloom-error-diolog/bloom-error-diolog.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MetaService } from '../../../bloom/services/meta-service';
import { ConnectionService } from '../../organization/connection.service';
import { BloomLoginComponent } from '../bloom-login/bloom-login.component';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { BloomLoginDiologComponent } from '../bloom-login-diolog/bloom-login-diolog.component';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { SmsService } from 'src/app/shared/services/sms-service.service';

@Component({
  selector: 'app-viewer-canvas',
  templateUrl: './viewer-canvas.component.html',
  styleUrls: ['./viewer-canvas.component.css']
})
export class ViewerCanvasComponent implements OnInit {

  currentBloomCode;
  currentURL: any
  version;
  versionError: boolean = false
  tokenError: boolean = false
  bloomMeta;
  page_structure;
  pages: any = [];
  ready: boolean = false
  preAuthToken: string = ''
  homePageCode: string
  pageCode: string
  versionExists: boolean = false
  pageCodeExists: boolean = false
  terminationError: boolean = false
  pageCodeList: string[] = []     // only contains non-hidden page codes
  isLoggedIn: boolean = false;
  hasPermission: boolean = false;
  isLogin: boolean;
  isLoginRequired: boolean;
  spinner: boolean = false;
  isLandingPageLogin: boolean;
  routerSubscription: any;
  isCheckOnChangeRoute: boolean;
  isBrowser: boolean;
  showFooter: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    private pageService: PageService,
    private _snackBar: MatSnackBar,
    private connectionService: ConnectionService,
    private dialog: MatDialog,
    public resourcePermissionService: ResourcePermissionService,
    public authService: AuthServiceService,
    private title: Title,
    private meta: Meta,
    public smsService: SmsService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    // if(!this.isBrowser) return;
    if(this.route?.snapshot?.data?.bloomCode){
      this.pageService.isSubdomainBloom = true;
      this.currentBloomCode = this.route?.snapshot?.data?.bloomCode || null;
    }

    this.currentURL = this.router.url
    console.log("current url is", this.currentURL)

  }

  trackByFn(index, item) {
    return index;
  }

  metaSEOInit(bloomMeta){
    this.title.setTitle(`${bloomMeta?.name || 'Bloom'} - Bloom`);
    if(bloomMeta?.description)this.meta.updateTag({ name: 'description', content: bloomMeta?.description });
  }

  onActivate(event: any) {
    if (event.sendMessage) {
      event.messageEvent.subscribe((message: string) => {
        console.log("message received", message);
        this.showFooter = true
        console.log("show footer is ", this.showFooter)
      });
    }
  }

  hideFooter(event){
    this.showFooter = false
  }

  async ngOnInit() {
    // if(!this.isBrowser) return;

    let that = this;

    

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.onRouteChange(event);
      }
      });

        //------------SUBSCRIBE TO URL PARAMS: CHECK PAGE CODE------------------------------
        this.route.params.subscribe(routeData => {
          let version: any;
          console.log("route data received: ", routeData)
          if(routeData.bloomcode) this.currentBloomCode = routeData.bloomcode || undefined;
          this.route.queryParams.subscribe(async params => {
            version = params.v || "latest";
            if(!params.hasOwnProperty("v")) version = "latest";
            this.version = version;
            if(this.metaService.previewMode)  'draft';
          })

          console.log("bloom code:", this.currentBloomCode)
          console.log("version:", version)

          // check if version exists
          if(version) this.versionExists = true;

          // check if pagecode exists
          let urlParts: any[] = this.currentURL.split('/');
          console.log("url parts", urlParts)
          if(urlParts.length >= 3 && typeof urlParts[3] == 'string'){
            this.pageCodeExists = true
            this.pageCode = urlParts[3];
            if(this.pageCode.includes("?")) this.pageCode = urlParts[3].split("?")[0];
            console.log("pagecode exists", this.pageCode)
            this.metaService.bloomLoginPageCode = this.pageCode;
          }

          // validate bloomCode
          if(!this.currentBloomCode || typeof this.currentBloomCode !== 'string'){
            console.log("bloom code invalid!")
            this._snackBar.open("Bloom code in the URL is invalid");
            this.terminationError = true
            return
          }
        })

    if(this.terminationError || this.versionError) return
    this.metaService.publishedMode = true;
    await this.getBloomAndSetPermissions()
  }


  async getBloomAndSetPermissions(){
    console.log("final version", this.version)
    this.spinner = true;
    if(this.metaService.bloomMeta) this.bloomMeta = this.metaService.bloomMeta;

    if(!this.bloomMeta) this.bloomMeta = await this.metaService.getBloomAndPageStructure(this.currentBloomCode, this.version);
    else {
      this.metaService.fetchedBloomMeta.next(this.bloomMeta);
      this.metaService.page_structure.next(this.bloomMeta.page_structure_map);
    }

    // Check if the current product is "HYPERLIST" and a valid creator email exists.
    if (this.metaService?.bloomMeta?.interaction_options?.product === "HYPERLIST" 
      && this.metaService?.creator_email) {
      const userEmail = this.metaService.creator_email;
      const product = "HYPERLIST";
      const actionType = "FETCH_RECORDS";
      try {
        const user = await this.authService.getWorkspaceUser(userEmail);
        if (!user) throw new Error(`User not found for email: ${userEmail}`);

        this.authService.userProfile = user;
        await this.smsService.getSMSAuthentication(user, product);

        // Check the user's eligibility for the product (e.g., within quota)
        // If the user is not eligible (quota exceeded or other reason), show a dialog
        const eligibility = await this.smsService.checkEligibility(actionType, product);
        console.log("[Loading HyperList] User eligibility check result:", eligibility);

        if (!eligibility.isEligible) {
          // Open the quota dialog and wait untill the dialog closed
          await this.openQuotaDialog(product);
        }
        console.log('User is eligible and within the quota limits.');
      } catch (error) {
        console.error("Error occurred during authentication or quota check:", error);
      }
    } else {
      console.log("This is not the HyperList add-on. Eligibility check is skipped.");
    }

    if(this.isBrowser) this.metaSEOInit(this.metaService.bloomMeta);

    if (!this.bloomMeta?.published_url && !this.bloomMeta.publishedUrl && this.bloomMeta?.version !== 'draft') {
      return
    }

    if(this.bloomMeta.require_login && this.authService.isFromMobile && this.authService.userProfile){
      let userProfile = {
          email: this.authService.userProfile.email,
          given_name: this.authService.userProfile.first_name,
          family_name: this.authService.userProfile.last_name,
          picture: this.authService.userProfile.image_url
        }
        this.metaService.bloomUserMap = userProfile;
        this.metaService.bloomUserMap = await this.authService.handleUser(userProfile);
        this.hasPermission = await this.resourcePermissionService.setBloomPrivilages(this.bloomMeta, this.metaService.bloomUserMap);
        if(!this.hasPermission){
          console.log("dont have access")
          this.dialog.open(BloomErrorDiologComponent, {
            width: '90vw',
            height: '90vh',
            panelClass: "collabdiolog",
            data: {ERROR_CODE: "NO-BLOOM-ACCESS", userMap: this.metaService.bloomUserMap},
            disableClose: true
          });
        }
        this.getPageStructure(this.bloomMeta.page_structure_id);
    } else if(this.bloomMeta.access_type == "public" || !this.bloomMeta.access_type){
      this.hasPermission = await this.resourcePermissionService.setBloomPrivilages(this.bloomMeta, null);
      this.getPageStructure(this.bloomMeta.page_structure_id);
    } else if(this.bloomMeta.require_login && this.pageCodeExists && !this.metaService.bloomUserLoggedIn){
      this.connectionService.workSpaceId = this.bloomMeta.workspace_id;
      this.isLogin = true;

      if(this.bloomMeta?.landing_page_type == "page" && this.bloomMeta?.landing_page != this.pageCode){
        this.ready = false;
        this.pageCode = this.bloomMeta.landing_page;
        this.isCheckOnChangeRoute = true;
        this.router.navigate(['p', this.currentBloomCode, this.bloomMeta.landing_page]);
      } else if (this.bloomMeta?.landing_page_type == "page" && this.bloomMeta.landing_page == this.pageCode) {
        this.ready = false;
        this.isLandingPageLogin = true;
        this.getPageStructure(this.bloomMeta.page_structure_id);
      } else {
        this.ready = false;
        this.isLoginRequired = true;
      }

    } else if(this.bloomMeta.require_login && this.pageCodeExists && this.metaService.bloomUserLoggedIn) {
      await this.metaService.getUser();
      if(this.metaService.bloomUserMap) {
        this.isLoggedIn = true;
        this.metaService.bloomUserMap = this.metaService.bloomUserMap;
        this.metaService.bloomUserMap = await this.authService.handleUser(this.metaService.bloomUserMap);
        this.hasPermission = await this.resourcePermissionService.setBloomPrivilages(this.bloomMeta, this.metaService.bloomUserMap);
        if(!this.hasPermission){
          this.dialog.open(BloomErrorDiologComponent, {
            width: '90vw',
            height: '90vh',
            panelClass: "collabdiolog",
            data: {ERROR_CODE: "NO-BLOOM-ACCESS", userMap: this.metaService.bloomUserMap},
            disableClose: true
          });
        }
      }
      this.getPageStructure(this.bloomMeta.page_structure_id);
    }
    if(!this.pageCodeExists) this.getPageStructure(this.bloomMeta.page_structure_id);
    this.spinner = false;
  }

  private openQuotaDialog(product: string): Promise<void> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(QoutaPopupComponent, {
        minWidth: "260px",
        height: "auto",
        data: { type: 'FETCH_RECORDS', product: product },
        disableClose: true // Prevent dialog from closing manually
      });
  
      dialogRef.afterClosed().subscribe(() => {
        console.log('Dialog closed.');
        resolve();  // Resolving when the dialog closes
      });
    });
  }
  
  
  
  onRouteChange(e:any){
    if(!this.isCheckOnChangeRoute) return;
    console.log("on route change")
    this.ready = false;
    this.isLandingPageLogin = true;
    this.getPageStructure(this.bloomMeta.page_structure_id);
    this.isCheckOnChangeRoute = false;
    this.ready = true;
  }

  async loggedIn(useMap:any){
    this.spinner = true;
    await this.metaService.getUser();
    this.ready = false;
    if(this.metaService.bloomUserMap) {
      this.isLoginRequired = false;

      this.isLoggedIn = true;
      this.metaService.bloomUserMap = this.metaService.bloomUserMap;
      this.metaService.bloomUserMap = await this.authService.handleUser(this.metaService.bloomUserMap);
      this.hasPermission = await this.resourcePermissionService.setBloomPrivilages(this.bloomMeta, this.metaService.bloomUserMap);
      this.ready = true;
      if(!this.hasPermission){
        this.dialog.open(BloomErrorDiologComponent, {
          width: '90vw',
          height: '90vh',
          panelClass: "collabdiolog",
          data: {ERROR_CODE: "NO-BLOOM-ACCESS", userMap: this.metaService.bloomUserMap},
          disableClose: true
        });
      }

      console.log("login again")
      this.resetPage()
      this.getPageStructure(this.bloomMeta.page_structure_id);
    }
    this.spinner = false;

  }

  async bloomLogin(){
    let that = this;
    this.ready = false
    this.currentURL = this.router.url;
    this.isLoginRequired = true;
    this.isLoggedIn = false;
    // this.page_structure = null;
    // console.log("this.bloomMeta", that.bloomMeta)
    // that.connectionService.workSpaceId = that.bloomMeta.workspace_id;
    // await that.metaService.getUser();

    // console.log("this.connectionService", that.connectionService)

    // let dialogRef = that.dialog.open(BloomLoginDiologComponent, {
    //   width: '90vw',
    //   height: '90vh',
    //   panelClass: "collabdiolog",
    //   data: {bloomMap: this.bloomMeta, isFromDialog: true},
    //   disableClose: true
    // });

    // var diologResult = await dialogRef.afterClosed().toPromise();
    // if(diologResult.userMap) {
    //   this.metaService.bloomUserLoggedIn = true;
    //   that.isLoggedIn = true;
    //   that.metaService.bloomUserMap = diologResult.userMap;
    //   that.metaService.bloomUserMap = await that.authService.handleUser(diologResult.userMap);
    //   let hasPermission = await that.resourcePermissionService.setBloomPrivilages(this.bloomMeta, this.metaService.bloomUserMap);
    //   if(!hasPermission){
    //     let dialogRef = that.dialog.open(BloomErrorDiologComponent, {
    //       width: '90vw',
    //       height: '90vh',
    //       panelClass: "collabdiolog",
    //       data: {ERROR_CODE: "NO-BLOOM-ACCESS", userMap: diologResult.userMap},
    //       disableClose: true
    //     });
    //   }
    // }

    // this.resetPage()
    // this.getPageStructure(this.bloomMeta.page_structure_id);
  }

  getPageStructure(id){
    //2. fetch page_structure meta
    this.pages = [];
    // this.ready = false
    this.page_structure = this.bloomMeta.page_structure_map;
    this.page_structure.pages.forEach(async pageCode => {
      console.log(pageCode)

      // add only non-hidden pages to the list
      if(!this.page_structure[pageCode].hidden){
        this.pages.push({
          name: this.page_structure[pageCode].name || pageCode,
          code: pageCode
        })
      }
    });
    this.homePageCode = this.page_structure.homePageCode
    this.ready = true

    if(this.pageCodeExists) return;

    let queryParams = "";
    if(this.currentURL.includes("?"))  {
      let url = this.currentURL;
      this.currentURL = url.split("?")[0];
      queryParams = url.split("?")[1];
      if(queryParams.includes("v=latest")) queryParams = queryParams.replace(/v=latest&|v=latest/gi, "")
    }
    console.log("queryParams", queryParams)

    let path: string
    if(this.versionExists && this.pageCodeExists){
      path = `?${queryParams}` //${this.pageCode}
    }else if(this.versionExists && !this.pageCodeExists){
      path = `/${this.homePageCode}?${queryParams}`;
    }else if(!this.versionExists && !this.pageCodeExists){
      path = `/${this.homePageCode}?${queryParams}`;
    }

    if(this.version != "latest") path = path + `v=${this.version}`;
    let toUrl: string = path ? this.currentURL + path : this.currentURL
    console.log("toUrl-->", toUrl)
    this.router.navigateByUrl(toUrl);
    this.ready = true

  }

  resetPage(){
    this.metaService.fetchedBloomMeta.next(this.bloomMeta);
    this.metaService.page_structure.next(this.bloomMeta.page_structure_map);
  }

  async bloomLogout(){
    this.ready = false
    this.metaService.bloomUserMap = {};
    this.metaService.bloomUserLoggedIn = false;
    this.isLoggedIn = false;
    await this.resourcePermissionService.setBloomPrivilages(this.bloomMeta, null);
    if(this.bloomMeta.require_login){
      this.isLogin = true;
      this.ready = false;
      this.isLoginRequired = true;
      this.pages = [];
      // this.router.navigate(['p', this.currentBloomCode, "s", "sign-up"]);
    } else {
      this.resetPage()
      this.getPageStructure(this.bloomMeta.page_structure_id);
    }

  }
}
