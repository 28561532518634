<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path" *ngIf="spinner"></spinner>


<div *ngIf="onlyDisplay && connection" fxLayout="row">
  <div fxLayout="column" fxFlex="5">
    <mat-icon color="primary" matPrefix>contactless</mat-icon>
  </div>
  <div fxLayout="column" fxFlex="5" *ngIf="connection?.options?.box_logo_url">
    <img [src]="connection?.options?.box_logo_url" class="image-size" />
  </div>
  <div fxLayout="column" fxFlex="80">
    {{connection?.name}}
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>

<div fxLayout="row" *ngIf="!onlyDisplay">
  <mat-form-field style="width: 80%;">
    <mat-icon color="primary" matPrefix>contactless</mat-icon>
    <mat-label>
      <span>&nbsp;Choose {{connectionType ? connectionType : 'Connection'}}</span>
    </mat-label>
    <input matInput #connInput [formControl]="connectionListingACFrom" (focus)="setFilterOptions()"
      [matAutocomplete]="auto" [matTooltip]="connection?.name" />
    <button matTooltip="Clear selected {{connectionType ? connectionType : 'Connection'}}"
      *ngIf="connectionListingACFrom.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="clearConnection($event)">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunction"
      (optionSelected)="connectionSelected($event.option.value)">
      <mat-option *ngFor="let option of connectionListingOptions | async" [value]="option">
        <img [src]="option.options.box_logo_url" class="image-size" />&nbsp;
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="connectionListingACFrom?.errors?.errorMsg">
      {{ connectionListingACFrom.errors.errorMsg }}
    </mat-error>

  </mat-form-field>
  <div>
    <button *ngIf="!connectionListingACFrom.value" mat-icon-button color="primary" (click)="connectionPopup('create')"
      matTooltip="Create Connection" aria-label="View Available Apps">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>

  <div>
    <button *ngIf="options?.allowRefresh" mat-icon-button color="accent" (click)="refreshConnections()"
      matTooltip="Refresh Connections" aria-label="Refresh Connections">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div *ngIf="connectionId && connectionListingACFrom.value">
    <!-- Conditionally render based on screen size -->
    <ng-container *ngIf="isMobileScreen; else desktopMenu">
        <!-- Three dots icon to show more options -->
        <button mat-icon-button [matMenuTriggerFor]="optionsMenu" aria-label="More options">
            <mat-icon>more_vert</mat-icon>
        </button>
        <!-- Menu items under the three dots icon -->
        <mat-menu #optionsMenu="matMenu">
            <button mat-icon-button color="primary" (click)="connectionPopup('edit')" matTooltip="Edit Connection"
                aria-label="View Available Apps">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteConnectionData()" [ngStyle]="{'color': 'red'}"
                matTooltip="Delete Connection" [ngClass.xs]="{'icon-button-small': true}">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-menu>
    </ng-container>
    <ng-template #desktopMenu>
        <!-- Direct buttons on larger screens -->
        <button mat-icon-button color="primary" (click)="connectionPopup('edit')" matTooltip="Edit Connection"
            aria-label="View Available Apps">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteConnectionData()" [ngStyle]="{'color': 'red'}"
            matTooltip="Delete Connection" [ngClass.xs]="{'icon-button-small': true}">
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>
  </div>

  <mat-spinner *ngIf="spinner" color="warn" [diameter]="20"></mat-spinner>
</div>
